import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './ContactForm.css';
import Select from 'react-select';
import Modal from 'react-modal';

const ContactForm = () => {
  const [startDate, setStartDate] = useState(null);
  const [timeSlot, setTimeSlot] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    phone: '',
    email: '',
    website: '',
    services: [],
    city: '',
    budget: '',
    message: '',
    industry: '',
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        services: checked
          ? [...prevData.services, { name: value }]
          : prevData.services.filter((service) => service.name !== value)
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleServiceChange = (selectedOptions) => {
    const services = selectedOptions ? selectedOptions.map(option => ({ name: option.value })) : [];
    setFormData((prevData) => ({
      ...prevData,
      services
    }));
  };

  const handleTimeSlotChange = (event) => {
    setTimeSlot(event.target.value);
    setFormData((prevData) => ({
      ...prevData,
      preferred_contact_time: event.target.value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const formattedData = {
      ...formData,
      created_at: new Date().toISOString()
    };

    fetch('https://advancetech.pythonanywhere.com/lead/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formattedData)
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        setIsSuccess(true);
        setModalIsOpen(true);
        setLoading(false);
        clearFormData();
      })
      .catch((error) => {
        console.error('Error:', error);
        setIsSuccess(false);
        setModalIsOpen(true);
        setLoading(false);
      });
  };

  const clearFormData = () => {
    setFormData({
      name: '',
      company: '',
      phone: '',
      email: '',
      website: '',
      services: [],
      city: '',
      budget: '',
      message: '',
      industry: '',
    });
    setStartDate(null);
    setTimeSlot('');
  };

  const closeModal = () => {
    setModalIsOpen(false);
    clearFormData();
  };

  // Define today's date + 1
  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);

  const servicesList = [
    { value: 'Performance Marketing', label: 'Performance Marketing' },
    { value: 'Website Development', label: 'Website Development' },
    { value: 'SEO', label: 'SEO' },
    { value: 'Video Editing', label: 'Video Editing' },
    { value: 'Graphics Designing', label: 'Graphics Designing' },
    { value: 'Ecommerce Branding', label: 'Ecommerce Branding' },
    { value: 'Business Consultation', label: 'Business Consultation' },
    { value: 'Marketplace Management', label: 'Marketplace Management' },
    { value: 'Social Media Handling', label: 'Social Media Handling' },
    { value: 'All Services', label: 'All Services' }
  ];

  const contactMethods = [
    { value: 'phone', label: 'Phone' },
    { value: 'email', label: 'Email' }
  ];

  return (
    <div className="contactus-web-container">
      <div className="contactus-web-left">
        <h1>Ready to think big?</h1>
        <p>Let us help disrupt your industry.</p>
      </div>
      <div className="contactus-web-right">
        <h2>Your Search For Digital Marketing Agency Ends Here</h2>
        <p>Fill out the form below. We'd be happy to get in touch with you.</p>
        <form className="contactus-web-form" onSubmit={handleSubmit}>
          <div className="contactus-web-form-group">
            <input type="text" name="name" placeholder="Name" className="contactus-web-input" value={formData.name} onChange={handleChange} required />
            <input type="text" name="company" placeholder="Company" className="contactus-web-input" value={formData.company} onChange={handleChange} required />
          </div>

          <div className="contactus-web-form-group">
            <input type="tel" name="phone" placeholder="+91" className="contactus-web-input" value={formData.phone} onChange={handleChange} required />
            <input type="email" name="email" placeholder="Email" className="contactus-web-input" value={formData.email} onChange={handleChange} required />
          </div>

          <div className="contactus-web-form-group">
            <input type="text" name="website" placeholder="Website" className="contactus-web-input full-width" value={formData.website} onChange={handleChange} required />
            <input type="text" name="industry" placeholder="Industry" className="contactus-web-input full-width" value={formData.industry} onChange={handleChange} required />
          </div>

          <div className="contactus-web-form-group">
            <input type="text" name="city" placeholder="City" className="contactus-web-input full-width" value={formData.city} onChange={handleChange} required />
            <input type="text" name="budget" placeholder="Budget" className="contactus-web-input full-width" value={formData.budget} onChange={handleChange} required />
          </div>

          <div className="contactus-web-form-group">
            <Select
              isMulti
              name="services"
              options={servicesList}
              className="contactus-web-select"
              classNamePrefix="select"
              onChange={handleServiceChange}
              value={servicesList.filter(service => formData.services.some(selectedService => selectedService.name === service.value))}
              placeholder="Services"
            />
          </div>

          <div className="contactus-web-form-group">
            <textarea name="message" placeholder="Message" className="contactus-web-input full-width" value={formData.message} onChange={handleChange}></textarea>
          </div>

          <button type="submit" className="contactus-web-submit" disabled={loading}>
            {loading ? <div className="loading-spinner"></div> : 'Submit'}
          </button>
        </form>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Response Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <h2>{isSuccess ? 'Thank You!' : 'Submission Failed'}</h2>
        <p>
          {isSuccess 
            ? 'Thanks for getting in touch with us. We will get back to you shortly.' 
            : 'There was an error submitting your form. Please try again later.'
          }
        </p>
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
};

export default ContactForm;
