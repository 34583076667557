import React from 'react'
import '../WebsiteDevelopment/WebsiteDevelopment.css';
import banner from '../../media/bannerwesite.webp'
import web2 from '../../media/shopify.webp';
import wordpress from '../../media/wordpress.webp';
import wix from '../../media/wix.webp';
import html from '../../media/html.webp';
import react from '../../media/react.webp';
import djnago from '../../media/djnago.webp';
import illustration from '../../media/web3.webp'
import CallToAction from '../CallToAction/CallToAction';
import ContactUs from '../ContactUs/ContactUs';
import { useNavigate } from 'react-router-dom';

import p1 from '../../media/p3.webp'
import p2 from '../../media/p1.webp'
import p3 from '../../media/p5.webp'
import p4 from '../../media/p6.webp'
import p5 from '../../media/p2.webp'
import ContactForm from '../ContactForm/ContactForm';

const testimonials = [
  {
    name: 'Sarvesh',
    rating: 5,
    text: 'Absolutely fantastic experience from start to finish. The professionalism and attention to detail were top-notch.',
    image: p1
  },
  {
    name: 'Manish',
    rating: 5,
    text: 'Very satisfied with the results. The service was impeccable and the staff were incredibly friendly and helpful.',
    image: p3
  },
  {
    name: 'Rishi',
    rating: 5,
    text: 'Outstanding service and support! I felt valued and well taken care of throughout the entire process.',
    image: p4
  },
  {
    name: 'Tara',
    rating: 5,
    text: 'Incredible experience! The team was professional, efficient, and truly cared about delivering the best service possible.',
    image: p5
  }
];




export default function WebsiteDevelopment() {

  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/contact-us-at-advance-techno');
  };

  
  return (
    <div className='homepage'>
      <div className="homepage-banner">
        <img src={banner} alt="Banner" className="homepage-banner-image" />
        <div className="homepage-banner-content">
          {/* <h1>Welcome to Advance Techno</h1> */}
          <h1>Transform Your Online Presence with Advance Techno</h1>
          <p>Innovative Website Development: Crafting Visually Stunning and Highly Functional Websites</p>
          <p>-<strong>Welcome to Advance Techno</strong></p>
          <button className="homepage-cta-button" onClick={handleContactClick}>Book A Call</button>
        </div>
      </div>

      <div className='website'>


      <section>
        <div className="website-design-dev-container">
      <h1 className="website-design-dev-title">
        WEBSITE <span className="highlight">DESIGN & DEVELOPMENT</span>
      </h1>
      <div className="website-design-dev-main-content">
        <div className="website-design-dev-text">
          <p>
            In today's scenario, presence on the web is essential. Whether you are an individual or a business owner, having a website that is accessible from round the globe 24*7 is essential if you wish to reach out to millions. A website has evolved dramatically from its early role as a basic linked information holder into a realm of rich media, high-end graphics, complex interactions and powerful data mining. The power of the internet can now be accessed in many ways, but websites remain a core component of any online presence.
          </p>
          <p>
            Websites are often the first point of contact between a company, organization, product or service and its users or customers. Thus, the user experience is vitally important, whether the site is a simple "brochure" presence or a more intricate web-based application. A carefully developed website can perform numerous tasks, often simultaneously. Skilled designers and programmers can ensure that a site not only looks and feels good, but also that it meets the business' or organization's objectives, whatever they might be.
          </p>
          <p>
            We offer affordable web design & development solutions ranging from a basic personal portfolio website to complex web portals, e-commerce websites, and content management systems. Using highest quality standards and frameworks, our application development team delivers flexible, robust and secure solutions.
          </p>
        </div>
        <div className="website-design-dev-illustration">
          <img src={illustration} alt="Web Design and Development" />
        </div>
      </div>

    </div>
        </section>



        <section className="platform-info">
          <h2 className="platform-info-title">Platform-specific Information</h2>
          <div className="platform-info-item">
            <div className="platform-info-logo">
              <img src={web2} alt="Shopify Logo" />
            </div>
            <div>
              <h3 className="platform-info-heading">Shopify</h3>
              <p className="platform-info-description">Discover how our Shopify experts can create seamless e-commerce solutions tailored to your business needs. From setting up your online store to integrating payment gateways and optimizing for conversions, we've got you covered.</p>
            </div>
          </div>
          <div className="platform-info-item">
            <div className="platform-info-logo">
              <img src={wordpress} alt="WordPress Logo" />
            </div>
            <div>
              <h3 className="platform-info-heading">WordPress</h3>
              <p className="platform-info-description">Explore the endless possibilities of WordPress with our experienced team. Whether you need a simple blog or a complex corporate website, we can design and develop a WordPress solution that meets your requirements and exceeds your expectations.</p>
            </div>
          </div>
          <div className="platform-info-item">
            <div className="platform-info-logo">
              <img src={wix} alt="Wix Logo" />
            </div>
            <div>
              <h3 className="platform-info-heading">Wix</h3>
              <p className="platform-info-description">Unlock the full potential of Wix with our customization and development services. We can transform your Wix website into a professional and engaging online presence, utilizing Wix's intuitive platform to showcase your brand and captivate your audience.</p>
            </div>
          </div>
          <div className="platform-info-item">
            <div className="platform-info-logo">
              <img src={html} alt="HTML Logo" />
            </div>
            <div>
              <h3 className="platform-info-heading">HTML</h3>
              <p className="platform-info-description">Harness the power of HTML to build sleek and responsive websites from scratch. Our HTML experts ensure clean, semantic code and pixel-perfect designs, resulting in websites that load fast and look great on any device.</p>
            </div>
          </div>
          <div className="platform-info-item">
            <div className="platform-info-logo">
              <img src={react} alt="React Logo" />
            </div>
            <div>
              <h3 className="platform-info-heading">React</h3>
              <p className="platform-info-description">Experience the latest in front-end development with React. Our React developers create dynamic and interactive user interfaces that enhance user experience and streamline navigation. Let us elevate your web presence with the power of React.</p>
            </div>
          </div>
          <div className="platform-info-item">
            <div className="platform-info-logo">
              <img src={djnago} alt="Django Logo" />
            </div>
            <div>
              <h3 className="platform-info-heading">Django</h3>
              <p className="platform-info-description">Empower your web applications with Django, the high-level Python web framework. Our Django developers build robust and scalable web solutions, leveraging Django's built-in features for authentication, security, and database management.</p>
            </div>
          </div>
        </section>

        
        <section className="expertise">
                <h2 className="expertise-title">Expertise and Experience</h2>
                <div className="expertise-content">
                    <div className="expertise-item">
                        <h3 className="expertise-heading">Custom Website Development</h3>
                        <p className="expertise-description">We specialize in crafting custom websites tailored to your unique business needs. Our experienced team of developers ensures that your website stands out with its original design and features.</p>
                    </div>
                    <div className="expertise-item">
                        <h3 className="expertise-heading">E-commerce Solutions</h3>
                        <p className="expertise-description">With expertise in platforms like Shopify, WooCommerce, and Magento, we create seamless e-commerce solutions that drive sales and enhance customer experience. From product listings to secure payment gateways, we've got you covered.</p>
                    </div>
                    <div className="expertise-item">
                        <h3 className="expertise-heading">Responsive Design</h3>
                        <p className="expertise-description">We prioritize responsive design to ensure your website looks and functions flawlessly across all devices and screen sizes. From desktops to smartphones, your audience will enjoy a consistent and user-friendly experience.</p>
                    </div>
                    <div className="expertise-item">
                        <h3 className="expertise-heading">Content Management Systems</h3>
                        <p className="expertise-description">Our team is proficient in various content management systems (CMS) such as WordPress, Drupal, and Joomla. We leverage these platforms to empower you with easy content management and website updates.</p>
                    </div>
                    {/* <div className="expertise-item">
                        <h3 className="expertise-heading">SEO Optimization</h3>
                        <p className="expertise-description">We implement SEO best practices to improve your website's visibility and ranking on search engine results pages (SERPs). From keyword research to on-page optimization, we help you attract organic traffic and grow your online presence.</p>
                    </div> */}
                </div>
            </section>








      </div>


                      <ContactForm/>
      <div className="testimonials-container">
        <h1>What Our Clients Say About Us</h1>
      <div className="testimonials-inner">
        {testimonials.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
            <h3>{testimonial.name}</h3>
            <div className="rating">
              {'★'.repeat(testimonial.rating)}
            </div>
            <p>{testimonial.text}</p>
          </div>
        ))}
      </div>
    </div>



      <CallToAction/>
    </div>

  )
}
