import React from 'react'
import bannerpm from '../../media/bannerpm.jpg'
import web3 from '../../media/web3.webp';
import './PerformanceMarketing.css'
import web2 from '../../media/fb.webp';
import wordpress from '../../media/ig.webp';
import wix from '../../media/google.webp';
import html from '../../media/sc.webp';
import react from '../../media/tt.webp';
import djnago from '../../media/ln.webp';
import CallToAction from '../CallToAction/CallToAction';
import {useNavigate } from 'react-router-dom';
import p1 from '../../media/p3.jpg'
// import p2 from '../../media/p1.jpg'
import p3 from '../../media/p5.jpg'
import p4 from '../../media/p6.jpg'
import p5 from '../../media/p2.jpg'
import ContactForm from '../ContactForm/ContactForm';



export default function PerformanceMarketing() {

  const navigate = useNavigate();

  const handleClickCOntact = () => {
    navigate(`/contact-us-at-advance-techno`);
  };

  const domains = [
    {
      title: 'E-commerce',
      description: 'Advance Techno crafts targeted campaigns to drive traffic, increase conversions, and maximize revenue for online stores, leveraging strategies.',
      dark: true,
    },
    {
      title: 'B2B Services',
      description: 'With a focus on lead generation, Advance Techno designs customized campaigns to attract and engage decision-makers within businesses, utilizing content marketing, email campaigns, and LinkedIn advertising.',
      dark: false,
    },
    {
      title: 'Hospitality and Tourism',
      description: 'By tailoring SEO campaigns that speak to your target audience, we help you establish your law firm as a respected authority in the legal field.',
      dark: true,
    },
    {
      title: 'Healthcare',
      description: 'We develop specialized marketing solutions, including search engine marketing (SEM) for medical practices, content marketing to establish authority, and social media campaigns to engage patients.',
      dark: false,
    },
    {
      title: 'Education',
      description: 'Advance Techno employs digital marketing tactics to reach prospective students, including targeted advertising on education platforms and search engine optimization (SEO) for program pages.',
      dark: false,
    },
    {
      title: 'Real Estate',
      description: 'We implement strategies, including targeted advertising on real estate platforms, SEO optimization for property listings, and content marketing to showcase listings and expertise.',
      dark: true,
    },
    {
      title: 'Retail',
      description: 'Advance Techno develops omnichannel marketing strategies to drive online and offline sales for retail businesses, including local SEO optimization and social media advertising.',
      dark: false,
    },
    {
      title: 'Technology',
      description: 'We aim to help technology companies increase brand visibility, generate leads, and drive product adoption through targeted advertising and content marketing.',
      dark: true,
    },
  ];
  
  const testimonials = [
    {
      name: 'Aditya',
      rating: 5,
      text: 'The performance marketing service is outstanding! The team went above and beyond to ensure we achieved the best sales results. Highly recommended!',
      image: p1 // replace with actual image paths
    },
    {
      name: 'Sakshi',
      rating: 5,
      text: 'Absolutely fantastic experience from start to finish. The professionalism and attention to detail in optimizing our campaigns were top-notch.',
      image: p3
    },
    {
      name: 'Rohit',
      rating: 5,
      text: 'Very satisfied with the results. The service was impeccable, and the team helped us achieve incredible sales growth.',
      image: p4
    },
    {
      name: 'Priya',
      rating: 5,
      text: 'Exceeded my expectations in every way. The quality of service and the dedication to improving our marketing performance are unmatched.',
      image: p5
    },
  ];
  



  return (
    <div>
            <div className="homepage-banner">
        <img src={bannerpm} alt="Banner" className="homepage-banner-image" />
        <div className="homepage-banner-content">
          {/* <h1>Welcome to Advance Techno</h1> */}
          <h1>Boost Your Brand: Partner with Advance Techno, Your Top Digital Marketing Agency</h1>
          <p>Empowering Brands, Amplifying Reach, and Driving Success with Cutting-Edge Strategies</p>
          <p>-<strong>Welcome to Advance Techno</strong></p>

          <button className="homepage-cta-button" onClick={handleClickCOntact}>Set Up A Call</button>
        </div>
      </div>

      <section>
        <div className="website-design-dev-container">
      <h1 className="website-design-dev-title">
        PERFORMANCE <span className="highlight">MARKETING</span>
      </h1>
    </div>
        </section>


<div className='homepage'>
  
<section>
        <div className="performance-marketing-banner">
                <div className="performance-marketing-banner-content">
                    <h1 className="performance-marketing-banner-heading">Performance Marketing Services</h1>
                    <p>Welcome to our performance marketing services page. We specialize in crafting high-impact marketing campaigns tailored to your business goals. Let us help you achieve measurable results and drive growth for your business.</p>
                    <button onClick={handleClickCOntact}>Contact Us</button>
                </div>
                <div className="performance-marketing-banner-image">
                    <img src={web3} alt="Performance Marketing Banner" />
                </div>
            </div>

        </section>



        <section className="performance-marketing-platform-info">
                <h2 className="performance-marketing-platform-info-title">Platform-specific Information</h2>
                <div className="performance-marketing-platform-info-item">
                    <div className="performance-marketing-platform-info-logo">
                        <img src={web2} alt="Facebook Logo" />
                    </div>
                    <div>
                        <h3 className="performance-marketing-platform-info-heading">Facebook</h3>
                        <p className="performance-marketing-platform-info-description">Discover how our Facebook marketing experts can create engaging social media campaigns tailored to your business needs. From increasing brand awareness to driving conversions, we've got you covered on the world's largest social network.</p>
                    </div>
                </div>
                <div className="performance-marketing-platform-info-item">
                    <div className="performance-marketing-platform-info-logo">
                        <img src={wordpress} alt="Instagram Logo" />
                    </div>
                    <div>
                        <h3 className="performance-marketing-platform-info-heading">Instagram</h3>
                        <p className="performance-marketing-platform-info-description">Explore the visual storytelling potential of Instagram with our experienced team. Whether you need to grow your audience or promote your products, we can design and execute Instagram marketing strategies that resonate with your target audience.</p>
                    </div>
                </div>
                <div className="performance-marketing-platform-info-item">
                    <div className="performance-marketing-platform-info-logo">
                        <img src={wix} alt="Google Logo" />
                    </div>
                    <div>
                        <h3 className="performance-marketing-platform-info-heading">Google</h3>
                        <p className="performance-marketing-platform-info-description">Unlock the power of Google Ads with our customization and optimization services. We can help you reach your target audience through Google's vast advertising network, driving traffic and conversions for your business.</p>
                    </div>
                </div>
                <div className="performance-marketing-platform-info-item">
                    <div className="performance-marketing-platform-info-logo">
                        <img src={html} alt="Snapchat Logo" />
                    </div>
                    <div>
                        <h3 className="performance-marketing-platform-info-heading">Snapchat</h3>
                        <p className="performance-marketing-platform-info-description">Tap into the creativity of Snapchat to engage with a younger demographic. Our Snapchat marketing experts can help you create immersive ad experiences that resonate with Gen Z and drive results for your brand.</p>
                    </div>
                </div>
                <div className="performance-marketing-platform-info-item">
                    <div className="performance-marketing-platform-info-logo">
                        <img src={react} alt="TikTok Logo" />
                    </div>
                    <div>
                        <h3 className="performance-marketing-platform-info-heading">TikTok</h3>
                        <p className="performance-marketing-platform-info-description">Experience the viral potential of TikTok with our creative marketing strategies. Our TikTok experts can help your brand gain visibility and engagement in this rapidly growing platform, reaching millions of users worldwide.</p>
                    </div>
                </div>
                <div className="performance-marketing-platform-info-item">
                    <div className="performance-marketing-platform-info-logo">
                        <img src={djnago} alt="LinkedIn Logo" />
                    </div>
                    <div>
                        <h3 className="performance-marketing-platform-info-heading">LinkedIn</h3>
                        <p className="performance-marketing-platform-info-description">Empower your B2B marketing efforts with LinkedIn's professional networking platform. Our LinkedIn marketing specialists can help you generate leads, build relationships, and establish thought leadership in your industry.</p>
                    </div>
                </div>
            </section>



</div>


<div className="testimonials-container">
        <h1>What Our Clients Say About Us</h1>
      <div className="testimonials-inner">
        {testimonials.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
            <h3>{testimonial.name}</h3>
            <div className="rating">
              {'★'.repeat(testimonial.rating)}
            </div>
            <p>{testimonial.text}</p>
          </div>
        ))}
      </div>
    </div>





    <div className="embrace-performance-container">
      <h2 className="embrace-performance-heading">
        Redefine Possibilities – Embrace Performance Marketing with Advance Techno!
      </h2>
      <p className="embrace-performance-subheading">Domains We Cater To</p>
      <div className="embrace-performance-domains">
        {domains.map((domain, index) => (
          <div
            key={index}
            className={`embrace-performance-domain-item ${domain.dark ? 'embrace-performance-dark' : 'embrace-performance-light'}`}
          >
            <h3 className="embrace-performance-domain-title">{domain.title}</h3>
            <p className="embrace-performance-domain-description">{domain.description}</p>
          </div>
        ))}
      </div>
    </div>





<CallToAction/>
<ContactForm/>


    </div>
  )
}
