import React, { useState } from 'react';
import './Homepage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faMobileAlt, faShoppingCart, faBullhorn, faShareAlt, faSearch, faEnvelope, faPenNib, faCode, faFilm } from '@fortawesome/free-solid-svg-icons';
import { faLaptopCode, faVideo, faPaintBrush, faStore, faComments, faClipboardList,  faStar, faBullseye, faBuilding, faHandshake  } from '@fortawesome/free-solid-svg-icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import banner from '../../media/banner_22_11zon.webp'
import homeabout from '../../media/homeabout.webp'
import svggs from '../../media/way.webp'
import { useNavigate } from 'react-router-dom';
import CallToAction from '../CallToAction/CallToAction';
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';
import ContactForm from '../ContactForm/ContactForm';
import HomepageHero from './HomepageHero'


const HomePage = () => {

  const navigate = useNavigate();

  const handleHeroClick = () => {
    navigate('/about-advance-techno');
  };

  const handleContactClick = () => {
    navigate('/contact-us-at-advance-techno');
  };

  const handleCardClickWebsite = () => {
    navigate('/website-development-advance-techno-service');
  };

  const handleCardClickApp = () => {
    navigate('/seo-advance-techno-service');
  };

  const handleCardClickPerformanceMarketing = () => {
    navigate('/performance-marketing-advance-techno-service');
  };

  const handleCardClickLeadGeneration = () => {
    navigate('/lead-gen-advance-techno-service');
  };

  const handleCardClickGraphicsDesigning = () => {
    navigate('/graphics-designing-advance-techno-service');
  };

  const handleCardClickEcommerceBranding = () => {
    navigate('/ecommerce-branding-advance-techno-service');
  };

  const handleCardClickBusinessConsultation = () => {
    navigate('/business-consultation-advance-techno-service');
  };

  const handleCardClickMarketplaceManagement = () => {
    navigate('/marketplace-management-advance-techno-service');
  };

  const handleCardClickSocialMediaHandling = () => {
    navigate('/social-media-handling-advance-techno-service');
  };



  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,         // Enable autoplay
    autoplaySpeed: 2000,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: '1. What is SEO and why is it important?',
      answer: 'SEO stands for Search Engine Optimization. It is important because it helps your website rank higher on search engines, driving more organic traffic and visibility to your site.'
    },
    {
      question: '2. How can social media marketing help my business?',
      answer: 'Social media marketing can help increase brand awareness, engage with your audience, and drive traffic to your website. It’s a powerful tool for building relationships and generating leads.'
    },
    {
      question: '3. What is performance marketing?',
      answer: 'Performance marketing is a type of digital marketing where advertisers only pay when a specific action occurs, such as a click, sale, or lead. It ensures you get the most out of your marketing budget.'
    },
    {
      question: '4. How long does it take to see results from SEO?',
      answer: 'SEO is a long-term strategy. It can take anywhere from 3 to 6 months to start seeing noticeable results, depending on the competitiveness of your industry and the effectiveness of the SEO tactics used.'
    },
    {
      question: '5. What are the key elements of a successful website development?',
      answer: 'Successful website development includes a user-friendly design, fast loading times, mobile responsiveness, SEO optimization, and high-quality content that meets the needs of your audience.'
    },
    {
      question: '6. Why should I invest in a professionally designed website?',
      answer: 'A professionally designed website enhances your brand’s credibility, provides a better user experience, and helps convert visitors into customers. It’s a crucial element of your online presence.'
    },
    {
      question: '7. How can performance marketing improve my ROI?',
      answer: 'Performance marketing allows you to track and measure every aspect of your campaign, making it easier to optimize for better ROI. You only pay for successful actions, which makes your marketing spend more efficient.'
    },
    {
      question: '8. What social media platforms should my business be on?',
      answer: 'The choice of social media platforms depends on your target audience. Common platforms include Facebook, Instagram, Twitter, LinkedIn, and Pinterest. Research where your audience spends their time and focus your efforts there.'
    },
    {
      question: '9. What is the difference between on-page and off-page SEO?',
      answer: 'On-page SEO refers to optimizing elements within your website, such as content, meta tags, and internal links. Off-page SEO involves strategies outside your website, like backlinks and social media signals.'
    },
    {
      question: '10. How often should I update my website’s content?',
      answer: 'Regular updates to your website’s content can improve SEO and keep your audience engaged. Aim to add fresh content, such as blog posts or news updates, at least once a month.'
    }
  ];
  

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };




  return (
    <div className="homepage">



      <div className="homepage-banner">
        <img src={banner} alt="Banner" className="homepage-banner-image" />
        <div className="homepage-banner-content">
          <h1>Welcome to Advance Techno</h1>
          <h1>Elevate Your Brand: Partner with Advance Techno, Your Premier Digital Marketing Agency</h1>
          <p>India’s New Age Way To Empower Brands, Transforming Brands, Amplifying Reach, Empowering Success</p>
          <p>-<strong> Welcome to Advance Techno</strong></p>
          <button className="homepage-cta-button" onClick={handleContactClick}>Book A Call</button>
        </div>
      </div>

      
    <div className="carousel-home-domains-info">
      <h2 className="carousel-home-domains-title">Our Expertise In Industry</h2>
      <Slider {...settings}>
        <div className="carousel-home-domains-item" onClick={handleCardClickWebsite}>
          <div className="carousel-home-domains-logo">
            <FontAwesomeIcon icon={faLaptopCode} className="carousel-home-domains-icon" />
          </div>
          <h3 className="carousel-home-domains-heading">Website Development</h3>
        </div>
        <div className="carousel-home-domains-item" onClick={handleCardClickApp}>
          <div className="carousel-home-domains-logo">
            <FontAwesomeIcon icon={faSearchengin  } className="carousel-home-domains-icon" />
          </div>
          <h3 className="carousel-home-domains-heading">SEO</h3>
        </div>
        <div className="carousel-home-domains-item" onClick={handleCardClickPerformanceMarketing}>
          <div className="carousel-home-domains-logo">
            <FontAwesomeIcon icon={faChartLine} className="carousel-home-domains-icon" />
          </div>
          <h3 className="carousel-home-domains-heading">Performance Marketing</h3>
        </div>
        <div className="carousel-home-domains-item" onClick={handleCardClickLeadGeneration}>
        <div className="carousel-home-domains-logo">
          <FontAwesomeIcon icon={faBullseye} className="carousel-home-domains-icon" />
        </div>
        <h3 className="carousel-home-domains-heading">Lead Generation</h3>
      </div>
        <div className="carousel-home-domains-item" onClick={handleCardClickGraphicsDesigning}>
          <div className="carousel-home-domains-logo">
            <FontAwesomeIcon icon={faPaintBrush} className="carousel-home-domains-icon" />
          </div>
          <h3 className="carousel-home-domains-heading">Graphics Designing</h3>
        </div>
        <div className="carousel-home-domains-item" onClick={handleCardClickEcommerceBranding}>
          <div className="carousel-home-domains-logo">
            <FontAwesomeIcon icon={faShoppingCart} className="carousel-home-domains-icon" />
          </div>
          <h3 className="carousel-home-domains-heading">Ecommerce Branding</h3>
        </div>
        <div className="carousel-home-domains-item" onClick={handleCardClickBusinessConsultation}>
          <div className="carousel-home-domains-logo">
            <FontAwesomeIcon icon={faBullhorn} className="carousel-home-domains-icon" />
          </div>
          <h3 className="carousel-home-domains-heading">Business Consultation</h3>
        </div>
        <div className="carousel-home-domains-item" onClick={handleCardClickMarketplaceManagement}>
          <div className="carousel-home-domains-logo">
            <FontAwesomeIcon icon={faStore} className="carousel-home-domains-icon" />
          </div>
          <h3 className="carousel-home-domains-heading">Marketplace Management</h3>
        </div>
        <div className="carousel-home-domains-item" onClick={handleCardClickSocialMediaHandling}>
          <div className="carousel-home-domains-logo">
            <FontAwesomeIcon icon={faComments} className="carousel-home-domains-icon" />
          </div>
          <h3 className="carousel-home-domains-heading">Social Media Handling</h3>
        </div>
      </Slider>
    </div>


      <section className="advance-techno-section">
            <div className="advance-techno-content">
                <div className="advance-techno-image">
                    <img src={svggs} alt="Technology Growth" />
                </div>
                <div className="advance-techno-info">
                    <h2 className="advance-techno-title">Our Way To Successful Future</h2>
                    <p className="advance-techno-description"> At Advance Techno, we transform ideas into reality, ensuring your business stays ahead in the digital era. Our expert team leverages cutting-edge technology to provide innovative solutions that drive growth and success. </p>
                    <p className="advance-techno-description">We pride ourselves on delivering exceptional results through our comprehensive suite of services. Whether it’s custom software development, mobile app creation, or digital marketing strategies, we are dedicated to exceeding your expectations.  </p>
                    <p className="advance-techno-description"> With a client-centric approach, we tailor our solutions to meet your unique needs, ensuring seamless integration and optimal performance. Partner with Advance Techno, and together, we will pave the way to a prosperous future. </p>
                    <div className="advance-techno-about">
                        <div className="advance-techno-play-button" onClick={handleContactClick}>
                            <span className="play-icon" onClick={handleContactClick}>Contact Us </span> {/* Simple play icon */}
                        </div>
                        {/* <p className="advance-techno-about-text">ABOUT US</p>
                        <p className="advance-techno-watch-text">Watch our process!</p> */}
                    </div>
                </div>
            </div>
            <div className="advance-techno-stats">
                <div className="stat-item">
                    <h3 className="stat-value">$ 35k+</h3>
                    <p className="stat-description">CLIENTS REVENUE</p>
                </div>
                <div className="stat-item">
                    <h3 className="stat-value">16k+</h3>
                    <p className="stat-description">LEADS FOR CLIENTS</p>
                </div>
                <div className="stat-item">
                    <h3 className="stat-value">6.7k+</h3>
                    <p className="stat-description">PHONE CALLS</p>
                </div>
                <div className="stat-item">
                    <h3 className="stat-value">254+</h3>
                    <p className="stat-description">SUCCESSFUL PROJECTS</p>
                </div>
            </div>
        </section> 


        <section className="services-info">
  <div className="services-info-item" onClick={handleCardClickPerformanceMarketing}>
    <div className="services-info-logo">
      <FontAwesomeIcon icon={faChartLine} className="services-info-icon" />
    </div>
    <div>
      <h3 className="services-info-heading">Performance Marketing</h3>
      <p className="services-info-description">Boost your ROI with our performance marketing strategies. Drive targeted traffic and maximize conversions.</p>
    </div>
  </div>
  <div className="services-info-item" onClick={handleCardClickWebsite}>
    <div className="services-info-logo">
      <FontAwesomeIcon icon={faLaptopCode} className="services-info-icon" />
    </div>
    <div>
      <h3 className="services-info-heading">Website Development</h3>
      <p className="services-info-description">Create stunning, responsive websites that captivate your audience and drive conversions. Ensure seamless performance and a user-friendly experience.</p>
    </div>
  </div>
  <div className="services-info-item" onClick={handleCardClickApp}>
    <div className="services-info-logo">
      <FontAwesomeIcon icon={faSearch} className="services-info-icon" />
    </div>
    <div>
      <h3 className="services-info-heading">SEO</h3>
      <p className="services-info-description">Optimize your website for higher visibility and increased organic traffic with our expert SEO strategies.</p>
    </div>
  </div>
  <div className="services-info-item" onClick={handleCardClickLeadGeneration}>
    <div className="services-info-logo">
      <FontAwesomeIcon icon={faBullhorn} className="services-info-icon" />
    </div>
    <div>
      <h3 className="services-info-heading">Lead Generation</h3>
      <p className="services-info-description">Identify and engage potential customers with our professional lead generation services. Drive growth and enhance your sales pipeline.</p>
    </div>
  </div>
  <div className="services-info-item" onClick={handleCardClickGraphicsDesigning}>
    <div className="services-info-logo">
      <FontAwesomeIcon icon={faPaintBrush} className="services-info-icon" />
    </div>
    <div>
      <h3 className="services-info-heading">Graphics Designing</h3>
      <p className="services-info-description">Stand out with our creative graphic design solutions. Craft visually stunning designs that resonate with your audience.</p>
    </div>
  </div>
  <div className="services-info-item" onClick={handleCardClickEcommerceBranding}>
    <div className="services-info-logo">
      <FontAwesomeIcon icon={faShoppingCart} className="services-info-icon" />
    </div>
    <div>
      <h3 className="services-info-heading">Ecommerce Branding</h3>
      <p className="services-info-description">Enhance your ecommerce presence with our branding services. Create a cohesive and attractive brand identity that boosts sales.</p>
    </div>
  </div>
  <div className="services-info-item" onClick={handleCardClickBusinessConsultation}>
    <div className="services-info-logo">
      <FontAwesomeIcon icon={faBullhorn} className="services-info-icon" />
    </div>
    <div>
      <h3 className="services-info-heading">Business Consultation</h3>
      <p className="services-info-description">Receive strategic insights and practical solutions from our expert consultants. Overcome business challenges and achieve your goals.</p>
    </div>
  </div>
  <div className="services-info-item" onClick={handleCardClickMarketplaceManagement}>
    <div className="services-info-logo">
      <FontAwesomeIcon icon={faStore} className="services-info-icon" />
    </div>
    <div>
      <h3 className="services-info-heading">Marketplace Management</h3>
      <p className="services-info-description">Manage your online marketplace presence effectively with our comprehensive solutions. Optimize listings, handle customer service, and increase sales and visibility.</p>
    </div>
  </div>
  <div className="services-info-item" onClick={handleCardClickSocialMediaHandling}>
    <div className="services-info-logo">
      <FontAwesomeIcon icon={faComments} className="services-info-icon" />
    </div>
    <div>
      <h3 className="services-info-heading">Social Media Handling</h3>
      <p className="services-info-description">Build a strong online community and engage your audience effectively with our social media handling services. Create engaging content to enhance your brand presence.</p>
    </div>
  </div>
</section>




        <div className="homepage-about">
  <div className="homepage-about-section">
    <div className="homepage-about-left">
      <img src={homeabout} alt="Vision" className="homepage-about-image" />
    </div>
    <div className="homepage-about-right">
      {/* <h2>About Us</h2> */}
      <div className="homepage-about-us">
        <h3>Your Trusted Digital Marketing Partner</h3>
        <div className="homepage-about-services">
          <div className="homepage-about-service">
            <div className='homepage-about-sect'>
            <FontAwesomeIcon icon={faBullhorn} className="fa-fade-in fa-1x" />
            <p>Performance Marketing</p>
            </div>
            <div className="homepage-about-progress">
              <div className="homepage-about-progress-bar" data-progress="90"></div>
            </div>
          </div>
          <div className="homepage-about-service">
            <div className='homepage-about-sect'>
            <FontAwesomeIcon icon={faShareAlt} className="fa-fade-in fa-1x" />
            <p>Social Media Management</p>
            </div>

            <div className="homepage-about-progress">
              <div className="homepage-about-progress-bar" data-progress="88"></div>
            </div>
          </div>
          <div className="homepage-about-service">
<div className='homepage-about-sect'>            <FontAwesomeIcon icon={faSearch} className="fa-fade-in fa-1x" />
            <p>SEO Optimization</p>
            </div>
            <div className="homepage-about-progress">
              <div className="homepage-about-progress-bar" data-progress="92"></div>
            </div>
          </div>
          <div className="homepage-about-service">
<div className='homepage-about-sect'>
<FontAwesomeIcon icon={faEnvelope} className="fa-fade-in fa-1x" />
            <p>Email Marketing</p>
</div>
            <div className="homepage-about-progress">
              <div className="homepage-about-progress-bar" data-progress="91"></div>
            </div>
          </div>
          <div className="homepage-about-service">
       <div className='homepage-about-sect'>
       <FontAwesomeIcon icon={faPenNib} className="fa-fade-in fa-1x" />
            <p>Content Creation</p>
       </div>
            <div className="homepage-about-progress">
              <div className="homepage-about-progress-bar" data-progress="90"></div>
            </div>
          </div>
          <div className="homepage-about-service">
       <div className='homepage-about-sect'>
       <FontAwesomeIcon icon={faCode} className="fa-fade-in fa-1x" />
            <p>Website Development</p>
       </div>
            <div className="homepage-about-progress">
              <div className="homepage-about-progress-bar" data-progress="95"></div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div className="homepage-about-mission">
    <h3>Our Mission</h3>
    <div className="homepage-about-mission-items">
      <div className="homepage-about-mission-item">
        <div className="homepage-about-mission-icon">
          <FontAwesomeIcon icon={faChartLine} className="fa-fade-in" />
        </div>
        <p>Drive Digital Growth</p>
      </div>
      <div className="homepage-about-mission-item">
        <div className="homepage-about-mission-icon">
          <FontAwesomeIcon icon={faBullseye} className="fa-fade-in" />
        </div>
        <p>Deliver Measurable Results</p>
      </div>
      <div className="homepage-about-mission-item">
        <div className="homepage-about-mission-icon">
          <FontAwesomeIcon icon={faBuilding} className="fa-fade-in" />
        </div>
        <p>Build Strong Brands</p>
      </div>
      <div className="homepage-about-mission-item">
        <div className="homepage-about-mission-icon">
          <FontAwesomeIcon icon={faHandshake} className="fa-fade-in" />
        </div>
        <p>Provide Exceptional Service</p>
      </div>
    </div>
  </div>
  {/* <div className="homepage-about-vision">
    <h3>Our Vision</h3>
    <p>
      Our vision is to help businesses achieve their digital marketing goals and drive growth through innovative, creative, and effective marketing strategies.
    </p>
    <button className="homepage-about-learn-more" onClick={handleheroClick}>Learn more</button>
  </div> */}
</div>


{/* 
      <div className="homepage-perks">
        <h2 className="homepage-perks-title">The Perks of Choosing Us!</h2>
        <div className="homepage-perks-container">
          <div className="homepage-perk">
            <i className="homepage-perk-icon fas fa-toolbox"></i>
            <h3>All-in-One Solution</h3>
            <p>Access everything you need in a single place</p>
          </div>
          <div className="homepage-perk">
            <i className="homepage-perk-icon fas fa-handshake"></i>
            <h3>Expertise that Delivers</h3>
            <p>Partner with seasoned professionals who have a track record of success</p>
          </div>
          <div className="homepage-perk">
            <i className="homepage-perk-icon fas fa-briefcase"></i>
            <h3>Business Focus</h3>
            <p>Channel your energy into growing your business while we handle the rest</p>
          </div>
          <div className="homepage-perk">
            <i className="homepage-perk-icon fas fa-chart-line"></i>
            <h3>Profits on the Rise</h3>
            <p>Experience an increase in profits through our optimized strategies</p>
          </div>
          <div className="homepage-perk">
            <i className="homepage-perk-icon fas fa-clock"></i>
            <h3>Time Optimization</h3>
            <p>Free up your schedule to focus on what matters most while we manage the details</p>
          </div>
          <div className="homepage-perk">
            <i className="homepage-perk-icon fas fa-cogs"></i>
            <h3>Tailored Solutions</h3>
            <p>Receive customized strategies and services designed specifically for your business</p>
          </div>
        </div>
        <button className="homepage-perks-button">Explore Now</button>
      </div> */}


<div className="faq-section">
      <h2 className="faq-heading">Frequently Asked Questions</h2>
      <ul className="faq-list">
        {faqs.map((faq, index) => (
          <li key={index} className="faq-item">
            <div className="faq-question" onClick={() => handleToggle(index)}>
              {faq.question}
              <span className="faq-toggle-icon">{activeIndex === index ? '-' : '+'}</span>
            </div>
            {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
          </li>
        ))}
      </ul>
    </div>



<CallToAction/>
<ContactForm/>

    </div>

  );
};

export default HomePage;