import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Homepage from './components/Homepage/Homepage';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import AboutUs from './components/AboutUs/AboutUs';
import Services from './components/Services/Services';
import WebsiteDevelopment from './components/WebsiteDevelopment/WebsiteDevelopment';
import PerformanceMarketing from './components/PerformanceMarketing/PerformanceMarketing';
import BusinessConsultation from './components/BusinessConsultation/BusinessConsultation';
import Careers from './components/Careers/Careers';
import ContactUs from './components/ContactUs/ContactUs';
import EcommerceBranding from './components/EcommerceBranding/EcommerceBranding';
import GraphicsDesigning from './components/GraphicsDesigning/GraphicsDesigning';
import MarketplaceManagement from './components/MarketplaceManagement/MarketplaceManagement';
import SocialMediaHandling from './components/SocialMediaHandling/SocialMediaHandling';
import VideoEditing from './components/VideoEditing/VideoEditing';
import Work from './components/Work/Work';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import Policies from './components/Policies/Policies';
import Whatsapp from './components/Whatsapp/Whatsapp';
import NotFound from './components/Notfound/NotFound';
import SEO from './components/AppDevelopment/AppDevelopment';
import Cources from './components/Cources/Cources';

// Custom hook to scroll to top on route change
function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function App() {
  return (
    <>
      <div>
        <BrowserRouter>
          <div>
            {/* <Navbar1 /> */}
            <Navbar/>
            <Whatsapp/>
            <ScrollToTop />
            <Routes>
              <Route exact path="/" element={<Homepage />} />
              <Route exact path="/about-advance-techno" element={<AboutUs />} />
              <Route exact path="/services-advance-techno-all" element={<Services />} />
              <Route exact path="/website-development-advance-techno-service" element={<WebsiteDevelopment />} />
              <Route exact path="/performance-marketing-advance-techno-service" element={<PerformanceMarketing />} />
              <Route exact path="/seo-advance-techno-service" element={<SEO />} />
              <Route exact path="/business-consultation-advance-techno-service" element={<BusinessConsultation />} />
              <Route exact path="/careers-advance-techno" element={<Careers />} />
              <Route exact path="/contact-us-at-advance-techno" element={<ContactUs />} />
              <Route exact path="/ecommerce-branding-advance-techno-service" element={<EcommerceBranding />} />
              <Route exact path="/graphics-designing-advance-techno-service" element={<GraphicsDesigning />} />
              <Route exact path="/marketplace-management-advance-techno-service" element={<MarketplaceManagement />} />
              <Route exact path="/social-media-handling-advance-techno-service" element={<SocialMediaHandling />} />
              <Route exact path="/lead-gen-advance-techno-service" element={<VideoEditing />} />
              <Route exact path="/our-works-advance-techno-works" element={<Work />} />
              <Route exact path="/advance-techno-privacy-policy" element={<PrivacyPolicy />} />
              <Route exact path="/advance-techno-policies" element={<Policies />} />
              <Route exact path="/advance-techno-services-terms-condition" element={<TermsAndConditions />} />
              <Route exact path="/*" element={<NotFound />} />
              <Route exact path="/advance-techno-cources" element={<Cources />} />
            </Routes>
            <Footer/>
          </div>
        </BrowserRouter>
      </div>
    </>
  );
}
