import React from 'react';
import bannerSEO from '../../media/bannersmh.webp';
import seoImage from '../../media/smh.webp';
import '../AppDevelopment/AppDevelopment.css';
import CallToAction from '../CallToAction/CallToAction';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faUserTie, faHandshake, faRocket, faGavel, faClipboardCheck, 
        faSearch, faLink, faCog, faMapMarkerAlt, faFileAlt, 
        faBoxOpen, faShoppingCart, faPencilAlt, 
        faTachometerAlt, faCogs, faStore} from '@fortawesome/free-solid-svg-icons';
        import ContactForm from '../ContactForm/ContactForm';


import p1 from '../../media/p3.webp'
import p2 from '../../media/p1.webp'
import p3 from '../../media/p5.webp'
import p4 from '../../media/p6.webp'
import p5 from '../../media/p2.webp'

export default function BusinessConsultation() {

  const navigate = useNavigate();

  const services = [
    {
      icon: faSearch,
      title: 'Social Media Strategy',
      description: 'Advance Techno crafts targeted social media strategies to boost your brand’s presence, engage with your audience, and drive business growth.'
    },
    {
      icon: faLink,
      title: 'Content Creation',
      description: 'Our team creates compelling content tailored for your brand, ensuring consistent messaging and engagement across all social media platforms.'
    },
    {
      icon: faCog,
      title: 'Account Management',
      description: 'We handle your social media accounts, posting regular updates, responding to comments, and ensuring your brand remains active and engaging.'
    },
    {
      icon: faMapMarkerAlt,
      title: 'Audience Engagement',
      description: 'Engage with your audience through targeted campaigns and interactive posts, building a loyal community around your brand.'
    },
    {
      icon: faFileAlt,
      title: 'Analytics & Reporting',
      description: 'Advance Techno provides detailed analytics and reports, helping you understand your social media performance and make data-driven decisions.'
    },
    {
      icon: faChartLine,
      title: 'Ad Campaign Management',
      description: 'Our experts manage your social media ad campaigns, optimizing for maximum reach, engagement, and ROI.'
    },
    {
      icon: faCogs,
      title: 'Influencer Collaboration',
      description: 'Collaborate with influencers to amplify your brand’s reach and connect with a broader audience.'
    },
    {
      icon: faStore,
      title: 'Social Media Optimization',
      description: 'Optimize your social media profiles for better visibility and engagement, ensuring your brand stands out in the crowded social landscape.'
    },
    {
      icon: faShoppingCart,
      title: 'E-commerce Integration',
      description: 'Integrate your e-commerce platforms with social media to drive sales and provide a seamless shopping experience for your customers.'
    },
    {
      icon: faPencilAlt,
      title: 'Crisis Management',
      description: 'We help you navigate any social media crises, protecting your brand’s reputation and maintaining positive public relations.'
    },
    {
      icon: faTachometerAlt,
      title: 'Performance Monitoring',
      description: 'Track and improve your social media performance with our continuous monitoring and adjustment services.'
    },
    {
      icon: faBoxOpen,
      title: 'Event Promotion',
      description: 'Promote your events on social media, ensuring maximum visibility and engagement from your target audience.'
    }
  ];

  const handleClickContact = () => {
    navigate(`/contact-us-at-advance-techno`);
  };
  const domains = [
    {
      title: 'E-commerce',
      description: 'Advance Techno leverages social media to drive online traffic, increase conversions, and maximize revenue for online stores.',
      dark: true,
    },
    {
      title: 'B2B Services',
      description: 'We design customized social media strategies to attract and engage decision-makers within businesses.',
      dark: false,
    },
    {
      title: 'Hospitality and Tourism',
      description: 'Our tailored social media strategies help you establish your business as a respected authority in the hospitality and tourism industry.',
      dark: true,
    },
    {
      title: 'Healthcare',
      description: 'We develop specialized social media solutions, including local search optimization for medical practices and engaging content marketing.',
      dark: false,
    },
    {
      title: 'Education',
      description: 'Advance Techno employs social media strategies to reach prospective students, including targeted advertising and optimized program pages.',
      dark: false,
    },
    {
      title: 'Real Estate',
      description: 'We implement social media strategies, including targeted advertising and content marketing to showcase listings and expertise.',
      dark: true,
    },
    {
      title: 'Retail',
      description: 'Advance Techno develops omnichannel social media strategies to drive online and offline sales for retail businesses.',
      dark: false,
    },
    {
      title: 'Technology',
      description: 'We aim to help technology companies increase brand visibility and drive product adoption through targeted social media campaigns.',
      dark: true,
    },
  ];

  const testimonials = [
    {
      name: 'Aditya',
      rating: 5,
      text: 'The social media management service by Advance Techno is outstanding! The team went above and beyond to ensure our brand achieved significant online presence. Highly recommended!',
      image: p1
    },
    {
      name: 'Sakshi',
      rating: 5,
      text: 'Absolutely fantastic experience from start to finish. The professionalism and attention to detail in handling our social media accounts were top-notch.',
      image: p2
    },
    {
      name: 'Rohit',
      rating: 5,
      text: 'Very satisfied with the results. The social media management service was impeccable, and the team helped us achieve incredible growth.',
      image: p4
    },
    {
      name: 'Priya',
      rating: 5,
      text: 'Exceeded my expectations in every way. The quality of service and the dedication to improving our social media presence are unmatched.',
      image: p5
    },
  ];

  return (
    <div>
      <div className="homepage-banner">
        <img src={bannerSEO} alt="Social Media Management Banner" className="homepage-banner-image" />
        <div className="homepage-banner-content">
          <h1>Elevate Your Brand with Advance Techno's Expert Social Media Management Services</h1>
          <p>Empowering Brands, Amplifying Reach, and Driving Success with Cutting-Edge Social Media Strategies</p>
          <p>-<strong>Welcome to Advance Techno</strong></p>
          <button className="homepage-cta-button" onClick={handleClickContact}>Set Up A Call</button>
        </div>
      </div>

      <section>
        <div className="website-design-dev-container">
          <h1 className="website-design-dev-title">
            SOCIAL MEDIA <span className="highlight">MANAGEMENT</span> SERVICES
          </h1>
        </div>
      </section>

      <div className='homepage'>
        <section>
          <div className="seo-banner">
            <div className="seo-banner-content">
              <h1 className="seo-banner-heading">Expert Social Media Management Services</h1>
              <p>Welcome to Advance Techno's social media management services page. We specialize in crafting high-impact social media strategies tailored to your business goals. Let us help you achieve measurable results and drive growth for your business.</p>
              <button onClick={handleClickContact}>Contact Us</button>
            </div>
            <div className="seo-banner-image">
              <img src={seoImage} alt="Social Media Management Banner" />
            </div>
          </div>
        </section>
      </div>

      <div className="seo-our-seo-container">
        <h2 className="seo-our-seo-title">Our Social Media Management Services</h2>
        <p className="seo-our-seo-subtitle">Enhance Your Brand Presence and Achieve Sustainable Growth</p>
        <div className="seo-our-seo-content">
          {services.map((service, index) => (
            <div className="seo-our-seo-item" key={index}>
              <FontAwesomeIcon icon={service.icon} className="seo-our-seo-icon" />
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="seo-we-are-best-container">
        <h2 className="seo-we-are-best-title">Why Advance Techno's Social Media Management Services Are Better Than the Rest</h2>
        <p className="seo-we-are-best-subtitle">Our Solutions Drive Business Success: We've Proven it Over and Over Again</p>
        <div className="seo-we-are-best-content">
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faChartLine} className="seo-we-are-best-icon animated-icon" />
            <h3>Experienced Team</h3>
            <p>Our team of seasoned social media experts has a proven track record of delivering outstanding results for diverse industries.</p>
          </div>
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faUserTie} className="seo-we-are-best-icon animated-icon" />
            <h3>Customized Strategies</h3>
            <p>We develop tailored social media strategies to meet your specific business goals and target audience.</p>
          </div>
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faHandshake} className="seo-we-are-best-icon animated-icon" />
            <h3>Comprehensive Services</h3>
            <p>From strategy development to content creation and performance monitoring, we offer a full suite of social media management services.</p>
          </div>
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faRocket} className="seo-we-are-best-icon animated-icon" />
            <h3>Proven Results</h3>
            <p>Our clients have seen significant improvements in brand visibility, audience engagement, and overall social media performance.</p>
          </div>
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faGavel} className="seo-we-are-best-icon animated-icon" />
            <h3>Transparent Reporting</h3>
            <p>We provide detailed analytics and reports to keep you informed about your social media performance and ROI.</p>
          </div>
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faClipboardCheck} className="seo-we-are-best-icon animated-icon" />
            <h3>Continuous Improvement</h3>
            <p>We continuously monitor and adjust your social media strategies to ensure optimal performance and growth.</p>
          </div>
        </div>
      </div>

      {/* <div className="seo-we-work-container">
        <h2 className="seo-we-work-title">Industries We Work With</h2>
        <div className="seo-we-work-content">
          {domains.map((domain, index) => (
            <div className={`seo-we-work-item ${domain.dark ? 'seo-we-work-item-dark' : ''}`} key={index}>
              <h3>{domain.title}</h3>
              <p>{domain.description}</p>
            </div>
          ))}
        </div>
      </div> */}



      <div className="testimonials-container">
  <h1>What Our Clients Say About Us</h1>
  <div className="testimonials-inner">
    {testimonials.map((testimonial, index) => (
      <div className="testimonial-card" key={index}>
        <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
        <h3>{testimonial.name}</h3>
        <div className="rating">
          {'★'.repeat(testimonial.rating)}
        </div>
        <p>{testimonial.text}</p>
      </div>
    ))}
  </div>
</div>





      <div className="consultation-container">
        <CallToAction />
      </div>

      <div className="seo-bottom-container">
        <ContactForm />
      </div>
    </div>
  );
}
