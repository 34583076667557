import React from 'react';
import bannerSEO from '../../media/bannerseo.webp';
import seoImage from '../../media/seo1.webp';
import '../AppDevelopment/AppDevelopment.css';
import CallToAction from '../CallToAction/CallToAction';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faUserTie, faHandshake, faRocket, faGavel, faClipboardCheck, 
        faSearch, faLink, faCog, faMapMarkerAlt, faFileAlt, 
        faBoxOpen, faShoppingCart, faPencilAlt, 
        faTachometerAlt, faCogs, faStore} from '@fortawesome/free-solid-svg-icons';
import p1 from '../../media/p3.webp'
import p2 from '../../media/p1.webp'
import p3 from '../../media/p5.webp'
import p4 from '../../media/p6.webp'
import p5 from '../../media/p2.webp'
import ContactForm from '../ContactForm/ContactForm';


export default function SEO() {

  const navigate = useNavigate();

  const services = [
    {
      icon: faSearch,
      title: 'Keyword Research & Strategy',
      description: 'Advance Techno is an SEO agency that understands your niche and can boost your ranking for specific search terms. Your dedicated SEO specialist will review the keywords your website is currently ranking for, take your keyword wishlist and conduct additional keyword research to build a list that makes the most sense for your website and the competitive landscape.'
    },
    {
      icon: faLink,
      title: 'Link Building',
      description: 'Backlinks are a huge component of ranking higher in search results, but not just any link! That\'s why you should partner with an SEO company like Advance Techno that has a dedicated link building team and has connections with many high-quality and relevant websites.'
    },
    {
      icon: faCog,
      title: 'Technical SEO',
      description: 'Rankings can improve with optimizations on your website’s back-end, such as improving page speed. By fixing these errors, it makes it easier on search engines when they crawl your website. And when you make search engines happy, your rankings can make you even happier!'
    },
    {
      icon: faMapMarkerAlt,
      title: 'Local SEO',
      description: 'Statistics show that 64 percent of customers search for local businesses online. Improve your company’s local SEO rankings with Google Business Profile or Google My Business (GMB) optimization and attract high-intent customers. We optimize your GMB profile and manage your online reputation to grow your local following.'
    },
    {
      icon: faFileAlt,
      title: 'On-Page SEO',
      description: 'Enhance your online visibility and earn high-quality traffic. At Advance Techno, we ensure our SEO services are aligned with search engines\' best practices to boost your website’s trustworthiness. Our SEO experts publish unique, value-rich content, optimize your headlines and HTML tags, and utilize high-resolution images.'
    },
    {
      icon: faChartLine,
      title: 'Off-Page SEO',
      description: 'Off-page optimization is crucial in establishing brand credibility and increasing online exposure. Partner with our SEO company and let us help you demonstrate your industry expertise. We leverage social media, link building and influencer marketing to generate qualified links and positive ratings.'
    },
    {
      icon: faCogs,
      title: 'Franchise SEO',
      description: 'Supercharge your franchise marketing efforts with Advance Techno’s SEO services for corporate or local businesses. We publish geo-specific content, leverage Nextdoor advertising and develop custom web designs. Thrive is the SEO firm that’ll maximize your brand’s impact and increase your leads and sales.'
    },
    {
      icon: faStore,
      title: 'Shopify SEO',
      description: 'Strengthen your online store’s digital presence and expand your market reach. Our SEO experts offer 24/7 customer support for your urgent needs and concerns. We provide a Shopify SEO audit, Shopify web design, paid advertising and review response publishing services to optimize your customers’ Shopify experience.'
    },
    {
      icon: faShoppingCart,
      title: 'eCommerce SEO',
      description: 'Provide your customers with a 24/7 convenient shopping experience and increase your client retention rate. We offer specialized eCommerce SEO services to help your business grow its sales and revenue. Leverage Walmart Marketplace integrations to increase your sales volume.'
    },
    {
      icon: faPencilAlt,
      title: 'Content Writing',
      description: 'We have a team of writers that produce SEO-friendly content for your website, whether that’s creating share-worthy blog posts or optimizing pre-existing services and product pages with more content. We provide web page writing services to support your content needs and bolster your search marketing.'
    },
    {
      icon: faTachometerAlt,
      title: 'Conversion Rate Optimization (CRO)',
      description: 'Increase your customers\' lifetime value and grow your online revenue with Thrive’s CRO services. Our SEO company integrates pay-per-click marketing with our SEO services to maximize your conversion opportunities. We perform website analysis, landing page optimization and A/B split testing.'
    },
    {
      icon: faBoxOpen,
      title: 'Amazon SEO',
      description: 'Differentiate your brand on Amazon and get found by millions of customers who are ready to buy. We optimize your Amazon product listings, maximize ad placements and implement goal-oriented Amazon marketing services (AMS) SEO solutions to help you drive more sales.'
    }
  ];

  const handleClickContact = () => {
    navigate(`/contact-us-at-advance-techno`);
  };

  const domains = [
    {
      title: 'E-commerce',
      description: 'Advance Techno crafts targeted SEO campaigns to drive organic traffic, increase conversions, and maximize revenue for online stores.',
      dark: true,
    },
    {
      title: 'B2B Services',
      description: 'With a focus on lead generation, Advance Techno designs customized SEO strategies to attract and engage decision-makers within businesses.',
      dark: false,
    },
    {
      title: 'Hospitality and Tourism',
      description: 'By tailoring SEO campaigns that speak to your target audience, we help you establish your business as a respected authority in the hospitality and tourism industry.',
      dark: true,
    },
    {
      title: 'Healthcare',
      description: 'We develop specialized SEO solutions, including local search optimization for medical practices, content marketing to establish authority, and social media campaigns to engage patients.',
      dark: false,
    },
    {
      title: 'Education',
      description: 'Advance Techno employs SEO tactics to reach prospective students, including targeted advertising on education platforms and optimization for program pages.',
      dark: true,
    },
    {
      title: 'Real Estate',
      description: 'We implement strategies, including targeted advertising on real estate platforms, SEO optimization for property listings, and content marketing to showcase listings and expertise.',
      dark: false,
    },
    {
      title: 'Technology',
      description: 'Advance Techno aims to help technology companies increase brand visibility, generate leads, and drive product adoption through targeted advertising and content marketing.',
      dark: true,
    },

    {
      title: 'Food and Beverage',
      description: 'Advance Techno specializes in SEO for food and beverage businesses, optimizing online presence to attract customers, increase bookings, and drive revenue.',
      dark: false,
    },
  ];
  
  const testimonials = [
    {
      name: 'Aditya',
      rating: 5,
      text: 'The SEO service by Advance Techno is outstanding! The team went above and beyond to ensure we achieved the best search rankings and organic traffic. Highly recommended!',
      image: p1 // replace with actual image paths
    },

    {
      name: 'Rohit',
      rating: 5,
      text: 'Very satisfied with the results. The SEO service was impeccable, and the team helped us achieve incredible growth in organic traffic.',
      image: p3
    },
    {
      name: 'Priya',
      rating: 5,
      text: 'Exceeded my expectations in every way. The quality of service and the dedication to improving our search engine rankings are unmatched.',
      image: p5
    },
    {
      name: 'Vikram',
      rating: 5,
      text: 'Outstanding service and support! Our search rankings skyrocketed thanks to their strategic SEO efforts.',
      image: p4
    },

  ];

  return (
    <div>
      <div className="homepage-banner">
        <img src={bannerSEO} alt="SEO Banner" className="homepage-banner-image" />
        <div className="homepage-banner-content">
          <h1>Boost Your Search Rankings with Advance Techno's Expert SEO Services</h1>
          <p>Empowering Brands, Amplifying Reach, and Driving Success with Cutting-Edge SEO Strategies</p>
          <p>-<strong>Welcome to Advance Techno</strong></p>
          <button className="homepage-cta-button" onClick={handleClickContact}>Set Up A Call</button>
        </div>
      </div>

      <section>
        <div className="website-design-dev-container">
          <h1 className="website-design-dev-title">
            SEO <span className="highlight">SERVICES</span>
          </h1>
        </div>
      </section>

      <div className='homepage'>
        <section>
          <div className="seo-banner">
            <div className="seo-banner-content">
              <h1 className="seo-banner-heading">Expert SEO Services</h1>
              <p>Welcome to Advance Techno's SEO services page. We specialize in crafting high-impact SEO campaigns tailored to your business goals. Let us help you achieve measurable results and drive growth for your business.</p>
              <button onClick={handleClickContact}>Contact Us</button>
            </div>
            <div className="seo-banner-image">
              <img src={seoImage} alt="SEO Banner" />
            </div>
          </div>
        </section>

        {/* <section className="seo-platform-info">
          <h2 className="seo-platform-info-title">Platform-specific SEO Strategies</h2>
          <div className="seo-platform-info-item">
            <div className="seo-platform-info-logo">
              <img src={facebookLogo} alt="Facebook Logo" />
            </div>
            <div>
              <h3 className="seo-platform-info-heading">Facebook SEO</h3>
              <p className="seo-platform-info-description">Discover how Advance Techno's Facebook SEO experts can optimize your social media presence. From increasing visibility to driving engagement, we help you leverage Facebook for better search rankings.</p>
            </div>
          </div>
          <div className="seo-platform-info-item">
            <div className="seo-platform-info-logo">
              <img src={instagramLogo} alt="Instagram Logo" />
            </div>
            <div>
              <h3 className="seo-platform-info-heading">Instagram SEO</h3>
              <p className="seo-platform-info-description">Explore the visual potential of Instagram with Advance Techno's SEO strategies. We help you optimize your Instagram content to improve discoverability and engagement.</p>
            </div>
          </div>
          <div className="seo-platform-info-item">
            <div className="seo-platform-info-logo">
              <img src={googleLogo} alt="Google Logo" />
            </div>
            <div>
              <h3 className="seo-platform-info-heading">Google SEO</h3>
              <p className="seo-platform-info-description">Unlock the power of Google with Advance Techno's SEO services. We specialize in search engine optimization to improve your website's ranking on Google, driving organic traffic and conversions.</p>
            </div>
          </div>
          <div className="seo-platform-info-item">
            <div className="seo-platform-info-logo">
              <img src={snapchatLogo} alt="Snapchat Logo" />
            </div>
            <div>
              <h3 className="seo-platform-info-heading">Snapchat SEO</h3>
              <p className="seo-platform-info-description">Tap into the creativity of Snapchat with Advance Techno's SEO strategies. We help you optimize your content to reach and engage a younger audience on Snapchat.</p>
            </div>
          </div>
          <div className="seo-platform-info-item">
            <div className="seo-platform-info-logo">
              <img src={tiktokLogo} alt="TikTok Logo" />
            </div>
            <div>
              <h3 className="seo-platform-info-heading">TikTok SEO</h3>
              <p className="seo-platform-info-description">Experience the viral potential of TikTok with Advance Techno's SEO strategies. We help you optimize your TikTok content to gain visibility and engagement, reaching millions of users.</p>
            </div>
          </div>
          <div className="seo-platform-info-item">
            <div className="seo-platform-info-logo">
              <img src={linkedinLogo} alt="LinkedIn Logo" />
            </div>
            <div>
              <h3 className="seo-platform-info-heading">LinkedIn SEO</h3>
              <p className="seo-platform-info-description">Empower your B2B marketing efforts with LinkedIn's professional networking platform. Advance Techno's LinkedIn SEO specialists help you generate leads, build relationships, and establish thought leadership in your industry.</p>
            </div>
          </div>
        </section> */}
      </div>


      <div className="seo-our-seo-container">
      <h2 className="seo-our-seo-title">Our SEO Services</h2>
      <p className="seo-our-seo-subtitle">Increase Your Search Rankings and Get Discovered More Online</p>
      <div className="seo-our-seo-content">
        {services.map((service, index) => (
          <div className="seo-our-seo-item" key={index}>
            <FontAwesomeIcon icon={service.icon} className="seo-our-seo-icon" />
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </div>


      <div className="seo-we-are-best-container">
      <h2 className="seo-we-are-best-title">Why Advance Techno's SEO Services Are Better Than the Rest</h2>
      <p className="seo-we-are-best-subtitle">Our SEO Solutions Work: We’ve Proven it Over and Over Again</p>
      <div className="seo-we-are-best-content">
        <div className="seo-we-are-best-item">
          <FontAwesomeIcon icon={faChartLine} className="seo-we-are-best-icon animated-icon" />
          <h3>Experience Matters</h3>
          <p>
            For more than 15 years, Advance Techno has helped businesses in all industries grow their online presence to drive more revenue. We have the experience and client success stories to support any size business wanting to increase online traffic and grow its leads. Many of our SEO experts have been industry leaders for more than a decade. Our team collaborates. If someone has an issue, we all jump in.
          </p>
        </div>
        <div className="seo-we-are-best-item">
          <FontAwesomeIcon icon={faUserTie} className="seo-we-are-best-icon animated-icon" />
          <h3>Dedicated SEO Professionals</h3>
          <p>
            We believe our job is to make a technical, complicated and sometimes overwhelming search engine optimization process as simple as possible for our clients. One of the many ways we accomplish this is by providing each client with a single point of contact to manage your project. You get to know your Advance Techno professional well and they will get to know your business and understand your unique needs.
          </p>
        </div>
        <div className="seo-we-are-best-item">
          <FontAwesomeIcon icon={faHandshake} className="seo-we-are-best-icon animated-icon" />
          <h3>Relationships & Results</h3>
          <p>
            We live by our two primary core values: Relationships and Results. We’re here for every client. Our staff has helped businesses of all sizes - from small businesses to large, national franchises - achieve exponential growth. Our search engine optimization company believes in owning the voice of our clients and we work hard every day to prove it.
          </p>
        </div>
        <div className="seo-we-are-best-item">
          <FontAwesomeIcon icon={faRocket} className="seo-we-are-best-icon animated-icon" />
          <h3>We’re Always Getting Better</h3>
          <p>
            SEO is never stagnant and neither are we. SEO is always changing because of multiple Google algorithm updates each year that impact ranking factors and we are always on top of the latest news and trends. Our search engine optimization company is constantly learning new techniques and staying up-to-date to remain ahead of the curve. Rest assured, our SEO company is proactive and not reactive.
          </p>
        </div>
        <div className="seo-we-are-best-item">
          <FontAwesomeIcon icon={faGavel} className="seo-we-are-best-icon animated-icon" />
          <h3>We Play Fair</h3>
          <p>
            We value honesty and integrity in everything we do. We always play fair, using white hat SEO to drive results without spamming or gaming the system that can lead to possible Google penalties. Simply put: We don’t have to cheat to win in SEO. As a Google Premier Partner, we play by the rules and never jeopardize your business in any way. We never compromise our integrity.
          </p>
        </div>
        <div className="seo-we-are-best-item">
          <FontAwesomeIcon icon={faClipboardCheck} className="seo-we-are-best-icon animated-icon" />
          <h3>Transparent Reporting</h3>
          <p>
            While our competitors use smoke and mirrors, we maintain full transparency so you can see firsthand how our efforts are increasing your online revenue. Our custom, in-depth reporting includes Google Analytics reports to give you a full overview of your site’s SEO performance, regularly updated keyword rankings and a monthly breakdown of the SEO tasks we’ve performed. We can also provide monthly video summary reviewing the SEO improvements and strategy.
          </p>
        </div>
      </div>
    </div>

    <div className="testimonials-container">
  <h1>What Our Clients Say About Us</h1>
  <div className="testimonials-inner">
    {testimonials.map((testimonial, index) => (
      <div className="testimonial-card" key={index}>
        <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
        <h3>{testimonial.name}</h3>
        <div className="rating">
          {'★'.repeat(testimonial.rating)}
        </div>
        <p>{testimonial.text}</p>
      </div>
    ))}
  </div>
</div>


      <div className="embrace-seo-container">
        <h2 className="embrace-seo-heading">
          Redefine Possibilities – Embrace SEO with Advance Techno!
        </h2>
        <p className="embrace-seo-subheading">Domains We Cater To</p>
        <div className="embrace-seo-domains">
          {domains.map((domain, index) => (
            <div
              key={index}
              className={`embrace-seo-domain-item ${domain.dark ? 'embrace-seo-dark' : 'embrace-seo-light'}`}
            >
              <h3 className="embrace-seo-domain-title">{domain.title}</h3>
              <p className="embrace-seo-domain-description">{domain.description}</p>
            </div>
          ))}
        </div>
      </div>


      <CallToAction />
      <ContactForm/>
    </div>
  );
}
