import React from 'react';
import bannerSEO from '../../media/bannercons.webp';
import seoImage from '../../media/buscons.webp';
import '../AppDevelopment/AppDevelopment.css';
import CallToAction from '../CallToAction/CallToAction';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faUserTie, faHandshake, faRocket, faGavel, faClipboardCheck, 
        faSearch, faLink, faCog, faMapMarkerAlt, faFileAlt, 
        faBoxOpen, faShoppingCart, faPencilAlt, 
        faTachometerAlt, faCogs, faStore} from '@fortawesome/free-solid-svg-icons';
import ContactUs from '../ContactUs/ContactUs';
import p1 from '../../media/p3.webp'
import p2 from '../../media/p1.webp'
import p3 from '../../media/p5.webp'
import p4 from '../../media/p6.webp'
import p5 from '../../media/p2.webp'
import ContactForm from '../ContactForm/ContactForm';


export default function BusinessConsultation() {

  const navigate = useNavigate();

  const services = [
    {
      icon: faSearch,
      title: 'Market Analysis & Research',
      description: 'Advance Techno provides comprehensive market analysis to identify opportunities and challenges. Our experts help you understand market trends, customer behavior, and competitive landscape.'
    },
    {
      icon: faLink,
      title: 'Strategic Planning',
      description: 'Our strategic planning services are designed to align your business goals with actionable plans. We assist in creating roadmaps that drive growth and ensure sustainable success.'
    },
    {
      icon: faCog,
      title: 'Operational Optimization',
      description: 'Improve your business operations with our optimization services. We analyze your processes, identify inefficiencies, and implement solutions to enhance productivity and reduce costs.'
    },
    {
      icon: faMapMarkerAlt,
      title: 'Local Market Expansion',
      description: 'Expand your business presence in local markets with our targeted strategies. We help you optimize your local marketing efforts to attract and retain high-intent customers.'
    },
    {
      icon: faFileAlt,
      title: 'Financial Advisory',
      description: 'Advance Techno offers expert financial advisory services to help you manage your finances better. From budgeting to investment planning, we provide guidance to ensure financial stability.'
    },
    {
      icon: faChartLine,
      title: 'Business Growth Strategies',
      description: 'Our growth strategies are tailored to help your business scale effectively. We leverage data-driven insights to create plans that drive revenue and market share growth.'
    },
    {
      icon: faCogs,
      title: 'Technology Integration',
      description: 'Integrate the latest technologies into your business with our expert consultation. We help you choose and implement the right tech solutions to enhance efficiency and innovation.'
    },
    {
      icon: faStore,
      title: 'E-commerce Solutions',
      description: 'Boost your online sales with our e-commerce solutions. We provide end-to-end support, from setting up your online store to optimizing it for better performance and customer experience.'
    },
    {
      icon: faShoppingCart,
      title: 'Retail Optimization',
      description: 'Optimize your retail operations with our comprehensive services. We assist in inventory management, customer service enhancement, and marketing strategies to increase sales.'
    },
    {
      icon: faPencilAlt,
      title: 'Content Marketing',
      description: 'Engage your audience with our content marketing services. We create and distribute valuable content to attract, engage, and retain customers, driving profitable customer action.'
    },
    {
      icon: faTachometerAlt,
      title: 'Performance Analytics',
      description: 'Track and improve your business performance with our analytics services. We provide detailed reports and insights to help you make informed decisions and achieve your business goals.'
    },
    {
      icon: faBoxOpen,
      title: 'Product Launch Strategies',
      description: 'Ensure a successful product launch with our strategic support. We help you plan, execute, and manage product launches to maximize impact and reach your target audience effectively.'
    }
  ];

  const handleClickContact = () => {
    navigate(`/contact-us-at-advance-techno`);
  };

  const domains = [
    {
      title: 'E-commerce',
      description: 'Advance Techno crafts targeted consultation services to drive online traffic, increase conversions, and maximize revenue for online stores.',
      dark: true,
    },
    {
      title: 'B2B Services',
      description: 'With a focus on lead generation, Advance Techno designs customized business strategies to attract and engage decision-makers within businesses.',
      dark: false,
    },
    {
      title: 'Hospitality and Tourism',
      description: 'By tailoring strategies that speak to your target audience, we help you establish your business as a respected authority in the hospitality and tourism industry.',
      dark: true,
    },
    {
      title: 'Healthcare',
      description: 'We develop specialized solutions, including local search optimization for medical practices, content marketing to establish authority, and social media campaigns to engage patients.',
      dark: false,
    },
    {
      title: 'Education',
      description: 'Advance Techno employs strategies to reach prospective students, including targeted advertising on education platforms and optimization for program pages.',
      dark: false,
    },
    {
      title: 'Real Estate',
      description: 'We implement strategies, including targeted advertising on real estate platforms, optimization for property listings, and content marketing to showcase listings and expertise.',
      dark: true,
    },
    {
      title: 'Retail',
      description: 'Advance Techno develops omnichannel strategies to drive online and offline sales for retail businesses, including local optimization and social media advertising.',
      dark: false,
    },
    {
      title: 'Technology',
      description: 'We aim to help technology companies increase brand visibility, generate leads, and drive product adoption through targeted advertising and content marketing.',
      dark: true,
    },
  ];

  const testimonials = [
    {
      name: 'Aditya',
      rating: 5,
      text: 'The business consultation service by Advance Techno is outstanding! The team went above and beyond to ensure we achieved the best strategies for growth. Highly recommended!',
      image: p1
    },
    {
      name: 'Sakshi',
      rating: 5,
      text: 'Absolutely fantastic experience from start to finish. The professionalism and attention to detail in optimizing our business operations were top-notch.',
      image: p2
    },
    {
      name: 'Rohit',
      rating: 5,
      text: 'Very satisfied with the results. The consultation service was impeccable, and the team helped us achieve incredible growth.',
      image: p3
    },
    {
      name: 'Vikram',
      rating: 5,
      text: 'Outstanding service and support! Our business performance improved significantly thanks to their strategic efforts.',
      image: p4
    },
    {
      name: 'Ananya',
      rating: 5,
      text: 'Incredible experience! The team was professional, efficient, and truly cared about delivering the best results possible.',
      image: p5
    }
  ];

  return (
    <div>
      <div className="homepage-banner">
        <img src={bannerSEO} alt="Business Consultation Banner" className="homepage-banner-image" />
        <div className="homepage-banner-content">
          <h1>Transform Your Business with Advance Techno's Expert Consultation Services</h1>
          <p>Empowering Brands, Amplifying Reach, and Driving Success with Cutting-Edge Strategies</p>
          <p>-<strong>Welcome to Advance Techno</strong></p>
          <button className="homepage-cta-button" onClick={handleClickContact}>Set Up A Call</button>
        </div>
      </div>

      <section>
        <div className="website-design-dev-container">
          <h1 className="website-design-dev-title">
            BUSINESS <span className="highlight">CONSULTATION</span> SERVICES
          </h1>
        </div>
      </section>

      <div className='homepage'>
        <section>
          <div className="seo-banner">
            <div className="seo-banner-content">
              <h1 className="seo-banner-heading">Expert Business Consultation Services</h1>
              <p>Welcome to Advance Techno's business consultation services page. We specialize in crafting high-impact strategies tailored to your business goals. Let us help you achieve measurable results and drive growth for your business.</p>
              <button onClick={handleClickContact}>Contact Us</button>
            </div>
            <div className="seo-banner-image">
              <img src={seoImage} alt="Business Consultation Banner" />
            </div>
          </div>
        </section>
      </div>

      <div className="seo-our-seo-container">
        <h2 className="seo-our-seo-title">Our Business Consultation Services</h2>
        <p className="seo-our-seo-subtitle">Enhance Your Business Performance and Achieve Sustainable Growth</p>
        <div className="seo-our-seo-content">
          {services.map((service, index) => (
            <div className="seo-our-seo-item" key={index}>
              <FontAwesomeIcon icon={service.icon} className="seo-our-seo-icon" />
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="seo-we-are-best-container">
        <h2 className="seo-we-are-best-title">Why Advance Techno's Business Consultation Services Are Better Than the Rest</h2>
        <p className="seo-we-are-best-subtitle">Our Solutions Drive Business Success: We've Proven it Over and Over Again</p>
        <div className="seo-we-are-best-content">
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faChartLine} className="seo-we-are-best-icon animated-icon" />
            <h3>Decades of Expertise</h3>
            <p>
              With over two decades of industry experience, Advance Techno has helped businesses of all sizes achieve sustainable growth. Our seasoned professionals bring unparalleled expertise to every project, ensuring success.
            </p>
          </div>
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faUserTie} className="seo-we-are-best-icon animated-icon" />
            <h3>Personalized Approach</h3>
            <p>
              We understand that every business is unique. That's why we take a personalized approach to every client, tailoring our strategies to address specific challenges and capitalize on opportunities.
            </p>
          </div>
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faHandshake} className="seo-we-are-best-icon animated-icon" />
            <h3>Commitment to Success</h3>
            <p>
              At Advance Techno, we're committed to the success of our clients. Our dedicated team works tirelessly to deliver results, building long-lasting relationships based on trust and mutual success.
            </p>
          </div>
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faRocket} className="seo-we-are-best-icon animated-icon" />
            <h3>Continuous Innovation</h3>
            <p>
              Innovation is at the heart of what we do. We continuously explore new technologies and strategies to stay ahead of the curve, ensuring that our clients remain competitive in today's dynamic business landscape.
            </p>
          </div>
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faGavel} className="seo-we-are-best-icon animated-icon" />
            <h3>Ethical Practices</h3>
            <p>
              Integrity is non-negotiable for us. We adhere to the highest ethical standards in all our dealings, ensuring transparency, honesty, and fairness in everything we do.
            </p>
          </div>
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faClipboardCheck} className="seo-we-are-best-icon animated-icon" />
            <h3>Transparent Reporting</h3>
            <p>
              We believe in transparency. Our detailed reporting gives you full visibility into our processes and outcomes, allowing you to track progress and make informed decisions with confidence.
            </p>
          </div>
        </div>
      </div>

      <div className="testimonials-container">
        <h1>What Our Clients Say About Us</h1>
        <div className="testimonials-inner">
          {testimonials.map((testimonial, index) => (
            <div className="testimonial-card" key={index}>
              <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
              <h3>{testimonial.name}</h3>
              <div className="rating">
                {'★'.repeat(testimonial.rating)}
              </div>
              <p>{testimonial.text}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="embrace-seo-container">
        <h2 className="embrace-seo-heading">
          Unlock Your Business Potential – Partner with Advance Techno Today!
        </h2>
        <p className="embrace-seo-subheading">Industries We Serve</p>
        <div className="embrace-seo-domains">
          {domains.map((domain, index) => (
            <div
              key={index}
              className={`embrace-seo-domain-item ${domain.dark ? 'embrace-seo-dark' : 'embrace-seo-light'}`}
            >
              <h3 className="embrace-seo-domain-title">{domain.title}</h3>
              <p className="embrace-seo-domain-description">{domain.description}</p>
            </div>
          ))}
        </div>
      </div>
      <ContactForm/>

      <CallToAction />
    </div>
  );
}

