import '../Notfound/NotFound.css'
import React from 'react';


const NotFound = () => {
  return (
    <div className="not-found">
      <div className="not-found-bg"></div>
      <div className="not-found-content">
        <h1 className="not-found-title">404</h1>
        <p className="not-found-subtitle">Oops! The page you're looking for doesn't exist.</p>
        
      </div>
    </div>
  );
};

export default NotFound;
