import React from 'react';
import './AboutUs.css'; // Importing the CSS file
import banner from '../../media/aboutbanner.webp'
import aboutelexer from '../../media/aboutelexer.webp'
import aboutwho from '../../media/aboutwho.webp'
import aboutpack from '../../media/aboutface.webp'
import abouttc from '../../media/abouttc.webp'
import aboutplan from '../../media/aboutplan.webp'
import aboutwork from '../../media/aboutwork.webp'
import { useNavigate } from 'react-router-dom';
import CallToAction from '../CallToAction/CallToAction';
import ContactForm from '../ContactForm/ContactForm';

const AboutUs = () => {
  const navigate = useNavigate();


  const handleContactClick = () => {
    navigate('/contact_us_at_advance_techno');
  };
  return (
<div className='About-main'>

<div className="homepage-banner">
        <img src={banner} alt="Banner" className="homepage-banner-image" />
        <div className="homepage-banner-content">
          {/* <h1>Welcome to Advance Techno</h1> */}
          <h1>Our Mission, Your Success: Get to Know Advance Techno</h1>
        <p>Transforming Ideas into Impactful Campaigns – Partner with Us to Elevate Your Brand.</p>
          <button className="homepage-cta-button" onClick={handleContactClick} >Book A Call</button>
        </div>
      </div>
      {/* <BrandCarousel/> */}






    <section>
  <div className="about-whoweare-container">
    <div className="about-whoweare-content">
      <h1 className="about-whoweare-title">
        Who We Are and What We Do
      </h1>
      <div className="about-whoweare-divider">
        <div className="about-whoweare-line"></div>
      </div>
      <p className="about-whoweare-text">
        Advance Techno, a digital marketing agency based in India. We understand the importance of a brand's digital presence. We get to know them and their target audiences intimately, then we create, develop, and communicate brands and their messages in an impactful & engaging way on their digital media platforms.
      </p>
      <p className="about-whoweare-text">
        Advance Techno designs and implements a digital marketing campaign for its clients in such a way that adds value to potential and existing stakeholders and does not look like a mere company pushing a product.
      </p>
      <p className="about-whoweare-text">
        The best way for us to tell you our story is through the projects that we deliver for our clients – the following pages will show a brief about us, our services, and some of the best creative – we hope you like it!
      </p>
    </div>
    <div className="about-whoweare-image">
      <img src={aboutwho} alt="Marketing Guide" />
    </div>
  </div>
</section>



    <section>
      <div className="about-AdvanceTechno-container">
      <div className="about-AdvanceTechno-content">
        <h1 className="about-AdvanceTechno-title">
          Tired of Your Marketing Feeling Like Throwing Bananas at a Lion?
        </h1>
        <div className="about-AdvanceTechno-divider">
          <div className="about-AdvanceTechno-line"></div>
        </div>
        <p className="about-AdvanceTechno-text">
          The digital landscape can be a wild place. Algorithms change faster than a cheetah on Red Bull, and competitors lurk around every corner. That’s where Advance Techno steps in, where every pixel is filled with creativity, strategy, and innovation.
        </p>
        <p className="about-AdvanceTechno-text">
          A division from the visionary roots of Elixir Web Solutions, Advance Techno emerges as the dynamic offspring, redefining the digital marketing landscape with its distinct flair and unparalleled expertise.
        </p>
        <p className="about-AdvanceTechno-text">
          We’re your fearless guides, made of a passionate and experienced team of digital marketing specialists who are laser-focused on helping clients achieve their goals.
        </p>
      </div>
      <div className="about-AdvanceTechno-image">
        <img src={aboutelexer} alt="Marketing Guide" />
      </div>
    </div>
      </section>


<section>
<div className="about-pack-of-digital-marketers-container">
      <div className="about-pack-of-digital-marketers-content">
        <h1 className="about-pack-of-digital-marketers-title">
          We're a Pack of Digital Marketing Experts
        </h1>
        <div className="about-pack-of-digital-marketers-divider">
          <div className="about-pack-of-digital-marketers-line"></div>
        </div>
        <p className="about-pack-of-digital-marketers-text">
          Founded in 2009 by a crew of renegade marketers with a combined 50+ years of experience, we saw a growing need for building brands that resonate with their target audience. We ditched the stuffy suits and stale tactics. Instead, we built an agency that’s:
        </p>
        <ul className="about-pack-of-digital-marketers-list">
          <li className="about-pack-of-digital-marketers-item">
            <span className="about-pack-of-digital-marketers-icon">💡</span>
            <span><strong>Data-Driven:</strong> We use real-time intel to target your ideal customers with laser precision. No more throwing marketing spaghetti at the wall and hoping it sticks.</span>
          </li>
          <li className="about-pack-of-digital-marketers-item">
            <span className="about-pack-of-digital-marketers-icon">🔥</span>
            <span><strong>Passionate:</strong> We're obsessed with seeing your brand thrive. Your success is our fuel.</span>
          </li>
          <li className="about-pack-of-digital-marketers-item">
            <span className="about-pack-of-digital-marketers-icon">🎨</span>
            <span><strong>Creative Mavericks:</strong> We don't settle for boring. We craft unique, results-oriented campaigns that make your brand stand out from the crowd.</span>
          </li>
          <li className="about-pack-of-digital-marketers-item">
            <span className="about-pack-of-digital-marketers-icon">🔍</span>
            <span><strong>Transparent:</strong> We keep you in the loop with clear communication so you always know what's working and what's not.</span>
          </li>
        </ul>
      </div>
      <div className="about-pack-of-digital-marketers-image">
        <img src={aboutpack} alt="Marketing Guide" />
      </div>
    </div>
</section>



<section>
<div className="about-orange-banner">
      <div className="about-orange-banner__item">
        <h2 className="about-orange-banner__number">10X</h2>
        <p className="about-orange-banner__text">Increase in website traffic through our AI-powered SEO optimization techniques.</p>
      </div>
      <div className="about-orange-banner__item">
        <h2 className="about-orange-banner__number">95%</h2>
        <p className="about-orange-banner__text">Accuracy rate of our machine learning-based keyword research tool.</p>
      </div>
      <div className="about-orange-banner__item">
        <h2 className="about-orange-banner__number">300%</h2>
        <p className="about-orange-banner__text">Boost in conversion rates through our data-driven digital marketing strategies.</p>
      </div>
      <div className="about-orange-banner__item">
        <h2 className="about-orange-banner__number">24/7</h2>
        <p className="about-orange-banner__text">Real-time monitoring and optimization of your online presence through our advanced analytics.</p>
      </div>
    </div>
</section>





    <section className="about-webelive-in">
      <h2>We Believe In</h2>
      <p>
        We believe in a lot of things that shape the course of our regular day.
        Committing to deliver the solution that doesn't only solve the problem
        once but eliminates it forever is one of them. We are an agency that hustles
        to create an atmosphere of holistic growth for its partners and associates.
      </p>
      <div className="about-webelive-in-cards">
        <div className="about-webelive-in-card">
          <img src={abouttc} alt="" />
          <h3>TAKE CHARGE!</h3>
          <p>
            The moment you come onboard we thus, we inherit
            all your challenges and take complete ownership of
            sailing you through them. From your smallest
            objectives to biggest goals, we stay by your side.
          </p>
        </div>
        <div className="about-webelive-in-card">
        <img src={aboutplan} alt="" />

          <h3>THINK TOMORROW</h3>
          <p>
            While we put all our efforts in real time, the
            heart of our plan lies in shaping a tomorrow full
            of endless possibilities for you, on digital. Every
            step we take is to move your brand towards a
            bigger and better future.
          </p>
        </div>
        <div className="about-webelive-in-card">
        <img src={aboutwork} alt="" />

          <h3>CELEBRATE EVERYTHING!</h3>
          <p>
            When you do what you love, everything that
            comes on the way is a reason to celebrate. Not
            just our victories, we even celebrate our
            learnings by applying them wherever the need
            arises.
          </p>
        </div>
      </div>
    </section>


<ContactForm/>




<CallToAction/>


</div>
  );
};

export default AboutUs;
