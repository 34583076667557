import React from 'react'
import './Cources.css'
import banner from '../../media/bannerCources.jpg'
import learning from '../../media/learning.jpg'

export default function Cources() {
    return (
        <>


            <div className='homepage'>
                <div className="homepage-banner">
                    <img src={banner} alt="Banner" className="homepage-banner-image" />
                    <div className="homepage-banner-content">
                        {/* <h1>Welcome to Advance Techno</h1> */}
                        <h1>Transform Your Online Presence with Advance Techno</h1>
                        <p>Innovative Website Development: Crafting Visually Stunning and Highly Functional Websites</p>
                        <p>-<strong>Welcome to Advance Techno</strong></p>
                        <button className="homepage-cta-button">Book A Call</button>
                    </div>
                </div>
            </div>


            <div className="presentation-container">
                <div className="text-content">
                    <h1>Present education in beautiful way!</h1>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
                    </p>
                    <div className="buttons">
                        <button className="button">View More Courses</button>
                        <button className="button">View Our Services</button>
                    </div>
                </div>
                <div className="image-content">
                    <img src={learning} alt="Woman" />
                </div>
            </div>


        </>
    )
}
