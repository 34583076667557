import React from 'react';
import './CallToAction.css';

const CallToAction = () => {
  return (
    <div className="call-to-action-container">
      <p className="call-to-action-text">
        Let us disrupt your industry! <a href="/contact-us-at-advance-techno">Book A Call!</a>
      </p>
    </div>
  );
};

export default CallToAction;
