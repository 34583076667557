import React from 'react'
import ContactForm from '../ContactForm/ContactForm'
import bannerGraphics from '../../media/bannercontact.webp'; // Update the image source
import { Link, useNavigate } from 'react-router-dom';
import './ContactUs.css'
import MapComponent from './MapComponent'

export default function ContactUs() {

  const navigate = useNavigate();

  const handleClickContact = () => {
    navigate(`/contact_us_at_advance_techno`);
  };



  return (
    <div>
      <div className="homepage-banner">
        <img src={bannerGraphics} alt="Graphics Designing Banner" className="homepage-banner-image" />
        <div className="homepage-banner-content">
          <h1>Transform Your Vision into Reality</h1>
          <p>Partner with Advance Techno to revolutionize your industry with cutting-edge design and innovative strategies.</p>
          <p><strong>Welcome to Advance Techno</strong> – Your Gateway to Digital Excellence</p>
          {/* <button className="homepage-cta-button" onClick={handleClickContact}>Get in Touch</button> */}
        </div>
      </div>




      <ContactForm />
      <MapComponent/>
    </div>
  )
}
