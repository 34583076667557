import React from 'react';
import './Footer.css';
import logo from '../../media/adv.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h4>Quick Links</h4>
          <ul>
            <li><a onClick={() => handleNavigate('/')}>Home</a></li>
            <li><a onClick={() => handleNavigate('/about-advance-techno')}>About</a></li>
            <li><a onClick={() => handleNavigate('/our-works-advance-techno-works')}>Work</a></li>
            <li><a onClick={() => handleNavigate('/contact-us-at-advance-techno')}>Contact Us</a></li>
            <li><a onClick={() => handleNavigate('/services-advance-techno-all')}>Services</a></li>
            <li><a onClick={() => handleNavigate('/advance-techno-cources')}>Courses</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Our Services</h4>
          <ul>
            <li><a onClick={() => handleNavigate('/website-development-advance-techno-service')}>Website Development</a></li>
            <li><a onClick={() => handleNavigate('/performance-marketing-advance-techno-service')}>Performance Marketing</a></li>
            <li><a onClick={() => handleNavigate('/marketplace-management-advance-techno-service')}>Marketplace Management</a></li>
            <li><a onClick={() => handleNavigate('/business-consultation-advance-techno-service')}>Business Consultation</a></li>
            <li><a onClick={() => handleNavigate('/seo-advance-techno-service')}>Search Engine Optimization</a></li>
            <li><a onClick={() => handleNavigate('/social-media-handling-advance-techno-service')}>Social Media Handling</a></li>
            <li><a onClick={() => handleNavigate('/graphics-designing-advance-techno-service')}>Graphics Designing</a></li>
            <li><a onClick={() => handleNavigate('/lead-gen-advance-techno-service')}>Lead Generation</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Legal</h4>
          <ul>
            <li><a onClick={() => handleNavigate('/advance-techno-services-terms-condition')}>Terms & Condition</a></li>
            <li><a onClick={() => handleNavigate('/advance-techno-policies')}>Refund Policy</a></li>
            <li><a onClick={() => handleNavigate('/advance-techno-privacy-policy')}>Privacy Policy</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <img src={logo} alt="Logo" className="footer-logo" />
          <address>
            Advance Techno
            <br />
            A-804, Ashirwad Gokul Tower, Ajay Ahuja Nagar, Kota-324010<br />
            <a href="mailto:info@advancetechno.org">info@advancetechno.org</a><br />
            <a href="tel:+917976289796">+91 7976289796</a>
          </address>
          -------------------------------------------------------
          <address>
            Advance Techno
            <br />Lucknow, Uttar Pradesh, 226031<br />
            <a href="mailto:info@advancetechno.org">info@advancetechno.org</a><br />
            <a href="tel:+919918141119">+91 9918141119</a>
          </address>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-social">
          <a href="https://www.facebook.com/advancetechnoservices" aria-label="Facebook"><FontAwesomeIcon icon={faFacebook} /></a>
          <a href="https://www.linkedin.com/company/advance-techno-digital-marketing-agency/" aria-label="Linkedin"><FontAwesomeIcon icon={faLinkedinIn} /></a>
          <a href="https://www.instagram.com/theadvancetechno/" aria-label="Instagram"><FontAwesomeIcon icon={faInstagram} /></a>
        </div>
      </div>
      <div className="footer-legal">
        <p>
          Copyright © Advance Techno, Inc. All rights reserved.
          <a onClick={() => handleNavigate('/advance-techno-services-terms-condition')}> Terms of Use</a> | <a onClick={() => handleNavigate('/advance-techno-privacy-policy')}>Privacy Policy</a>
        </p>
        <p>
          info@advancetechno.org | TTY: +91 7976289796 | +91 9918141119
        </p>
      </div>
    </footer>
  );
};

export default Footer;
