import React from 'react';
import './Policies.css';

const Policy = () => {
  return (
    <div className="policy-container">
      <h2>Refund Policy for Advance Techno</h2>
      <section>
        <h3>Introduction</h3>
        <p>
          At Advance Techno, we strive to provide high-quality digital marketing services and IT training programs. This Refund Policy outlines the conditions under which refunds may be issued for our services. By purchasing our services, you agree to the terms of this Refund Policy.
        </p>
      </section>
      
      <section>
        <h3>Digital Marketing Services</h3>
        <h4>Mutual Agreement</h4>
        <p>
          Refunds for our digital marketing services will be considered on a case-by-case basis as per mutual discussion and agreement between Advance Techno and the client. The following conditions apply:
        </p>
        <ul>
          <li>The request for a refund must be made within 30 days of the service commencement date.</li>
          <li>The client must provide a valid reason for the refund request.</li>
          <li>Advance Techno will evaluate the request and determine if a refund is warranted based on the specific circumstances.</li>
        </ul>

        <h4>Refund Process</h4>
        <p>
          If a refund is approved, it will be processed within 14 business days. The refund will be issued to the original payment method used by the client. Any applicable fees or charges incurred by Advance Techno during the provision of services may be deducted from the refund amount.
        </p>
      </section>

      <section>
        <h3>IT Training Programs</h3>
        <h4>No Refunds After 7 Days</h4>
        <p>
          Advance Techno offers a 7-day satisfaction guarantee for our IT training programs. Refunds will be issued under the following conditions:
        </p>
        <ul>
          <li>The request for a refund must be made within 7 days of the training program start date.</li>
          <li>The client must provide a valid reason for dissatisfaction with the training program.</li>
        </ul>

        <h4>Refund Process</h4>
        <p>
          If a refund request is made within the 7-day period and meets the above conditions, the refund will be processed as follows:
        </p>
        <ul>
          <li>The refund request must be submitted in writing to <a href="mailto:support@advancetechno.com">support@advancetechno.com</a>.</li>
          <li>Advance Techno will review the request and, if approved, process the refund within 14 business days.</li>
          <li>The refund will be issued to the original payment method used by the client.</li>
        </ul>

        <h4>No Refunds After 7 Days</h4>
        <p>
          No refunds will be issued for IT training programs after the initial 7-day period from the start date of the training program. This policy is in place to ensure the quality and integrity of our training services.
        </p>
      </section>

      <section>
        <h3>General Conditions</h3>
        <h4>Non-Refundable Services</h4>
        <p>
          Certain services and products offered by Advance Techno may be non-refundable. These include, but are not limited to, customized training materials, downloadable content, and special promotions. Such services will be clearly marked as non-refundable at the time of purchase.
        </p>

        <h4>Force Majeure</h4>
        <p>
          Refunds will not be issued for delays or cancellations of services due to events beyond our control, including but not limited to natural disasters, acts of terrorism, or other unforeseen circumstances.
        </p>

        <h4>Contact Us</h4>
        <p>
          If you have any questions or need assistance with a refund request, please contact us at:
        </p>
        <address>
          Advance Techno<br />
          Email: <a href="mailto:info@advancetechno.org">info@advancetechno.org</a><br />
          Phone: +91 7976289796<br />
          Address: A-804, Ashirwad Gokul Tower, Ajay Ahuja Nagar, Kota-324010
        </address>
      </section>

      <p>Thank you for choosing Advance Techno. We are committed to providing exceptional services and appreciate your understanding of our refund policies.</p>
    </div>
  );
}

export default Policy;
