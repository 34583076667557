import React from 'react';
import bannerSEO from '../../media/bannerseo.webp';
import seoImage from '../../media/seo1.webp';
import '../AppDevelopment/AppDevelopment.css';
import facebookLogo from '../../media/fb.webp';
import instagramLogo from '../../media/ig.webp';
import googleLogo from '../../media/google.webp';
import snapchatLogo from '../../media/sc.webp';
import tiktokLogo from '../../media/tt.webp';
import linkedinLogo from '../../media/ln.webp';
import CallToAction from '../CallToAction/CallToAction';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faUserTie, faHandshake, faRocket, faGavel, faClipboardCheck, 
        faStore, faShoppingCart, faFileSignature, faCog, faSearch } from '@fortawesome/free-solid-svg-icons';

        import p1 from '../../media/p3.webp'
        import p2 from '../../media/p1.webp'
        import p3 from '../../media/p5.webp'
        import p4 from '../../media/p6.webp'
        import p5 from '../../media/p2.webp'
import ContactForm from '../ContactForm/ContactForm';


export default function MarketplaceManagement() {

  const navigate = useNavigate();

  const services = [
    {
      icon: faStore,
      title: 'Store Setup & Optimization',
      description: 'Advance Techno helps you set up and optimize your online store on various marketplace platforms. We ensure your store is user-friendly, visually appealing, and fully optimized for search engines to attract more customers and boost sales.'
    },
    {
      icon: faShoppingCart,
      title: 'Product Listing Management',
      description: 'Effective product listing management is crucial for marketplace success. Our team handles the creation, optimization, and management of your product listings to ensure they are accurate, compelling, and optimized for maximum visibility and sales.'
    },
    {
      icon: faFileSignature,
      title: 'Policy Compliance & Management',
      description: 'Navigating marketplace policies and regulations can be complex. Advance Techno ensures your store complies with all relevant policies, preventing any potential issues or penalties. We manage your store’s compliance to keep your operations running smoothly.'
    },
    {
      icon: faChartLine,
      title: 'Sales Performance Analysis',
      description: 'We provide detailed analysis of your sales performance across different marketplace platforms. Our insights help you understand your sales trends, customer behavior, and identify opportunities for growth and improvement.'
    },
    {
      icon: faRocket,
      title: 'Promotional Campaign Management',
      description: 'Maximize your reach and sales with effective promotional campaigns. Advance Techno plans and executes targeted promotional strategies to increase your product visibility and drive more sales on marketplace platforms.'
    },
    {
      icon: faCog,
      title: 'Inventory Management',
      description: 'Efficient inventory management is essential for maintaining stock levels and fulfilling orders promptly. Advance Techno offers comprehensive inventory management solutions to track stock levels, manage replenishment, and optimize inventory turnover.'
    },
    {
      icon: faHandshake,
      title: 'Vendor & Supplier Relations',
      description: 'Establishing and maintaining strong relationships with vendors and suppliers is key to sourcing quality products and ensuring timely deliveries. Advance Techno facilitates communication and collaboration with vendors to streamline operations and enhance product offerings.'
    },
    {
      icon: faClipboardCheck,
      title: 'Quality Assurance & Control',
      description: 'Maintaining product quality is vital for customer satisfaction and brand reputation. Advance Techno implements robust quality assurance processes to ensure that products meet the highest standards and comply with customer expectations.'
    },
    {
      icon: faSearch,
      title: 'Market Research & Analysis',
      description: 'Thorough market research and analysis are essential for identifying trends, understanding customer preferences, and identifying opportunities for growth. Advance Techno conducts comprehensive market research to provide actionable insights and strategies for success.'
    }
  ];
  
  const handleClickContact = () => {
    navigate(`/contact-us-at-advance-techno`);
  };

  const domains = [
    {
      title: 'E-commerce',
      description: 'Advance Techno crafts targeted marketplace management campaigns to drive organic traffic, increase conversions, and maximize revenue for online stores.',
      dark: true,
    },
    {
      title: 'B2B Services',
      description: 'With a focus on lead generation, Advance Techno designs customized marketplace management strategies to attract and engage decision-makers within businesses.',
      dark: false,
    },
    {
      title: 'Healthcare',
      description: 'We develop specialized marketplace management solutions, including compliance management for medical products, content marketing to establish authority, and social media campaigns to engage patients.',
      dark: true,
    },
    {
      title: 'Retail',
      description: 'Advance Techno develops omnichannel marketplace management strategies to drive online and offline sales for retail businesses, including local SEO optimization and social media advertising.',
      dark: false,
    },
    {
      title: 'Technology',
      description: 'We aim to help technology companies increase brand visibility, generate leads, and drive product adoption through targeted advertising and content marketing.',
      dark: true,
    },
    {
      title: 'Real Estate',
      description: 'Advance Techno implements marketplace management strategies tailored for real estate businesses, including targeted advertising on real estate platforms, SEO optimization for property listings, and content marketing to showcase listings and expertise.',
      dark: false,
    },
    {
      title: 'Education',
      description: 'We provide marketplace management solutions for educational institutions, including targeted advertising on education platforms, SEO optimization for program pages, and content marketing to attract prospective students.',
      dark: true,
    },
    {
      title: 'Automotive',
      description: 'Advance Techno offers marketplace management services for automotive businesses, including targeted advertising on automotive platforms, SEO optimization for vehicle listings, and content marketing to showcase products and services.',
      dark: false,
    },
  ];
  

  const testimonials = [
    {
      name: 'Aditya',
      rating: 5,
      text: 'The marketplace management service by Advance Techno is outstanding! The team went above and beyond to ensure we achieved the best search rankings and organic traffic. Highly recommended!',
      image: p1 // replace with actual image paths
    },
    {
      name: 'Sakshi',
      rating: 5,
      text: 'Absolutely fantastic experience from start to finish. The professionalism and attention to detail in optimizing our online store were top-notch.',
      image: p2
    },
    // {
    //   name: 'Rohit',
    //   rating: 5,
    //   text: 'Very satisfied with the results. The marketplace management service was impeccable, and the team helped us achieve incredible growth in sales.',
    //   image: bannerSEO
    // },
    {
      name: 'Priya',
      rating: 5,
      text: 'Exceeded my expectations in every way. The quality of service and the dedication to improving our marketplace presence are unmatched.',
      image: p5
    },
    {
      name: 'Vikram',
      rating: 5,
      text: 'Outstanding service and support! Our sales skyrocketed thanks to their strategic marketplace management efforts.',
      image: p4
    },

  ];

  return (
    <div>
      <div className="homepage-banner">
        <img src={bannerSEO} alt="Marketplace Management Banner" className="homepage-banner-image" />
        <div className="homepage-banner-content">
          <h1>Boost Your Sales with Advance Techno's Expert Marketplace Management Services</h1>
          <p>Empowering Brands, Amplifying Reach, and Driving Success with Cutting-Edge Strategies</p>
          <p>-<strong>Welcome to Advance Techno</strong></p>
          <button className="homepage-cta-button" onClick={handleClickContact}>Set Up A Call</button>
        </div>
      </div>

      <section>
        <div className="website-design-dev-container">
          <h1 className="website-design-dev-title">
            Marketplace <span className="highlight">MANAGEMENT SERVICES</span>
          </h1>
        </div>
      </section>

      <div className='homepage'>
        <section>
          <div className="seo-banner">
            <div className="seo-banner-content">
              <h1 className="seo-banner-heading">Expert Marketplace Management Services</h1>
              <p>Welcome to Advance Techno's marketplace management services page. We specialize in crafting high-impact campaigns tailored to your business goals. Let us help you achieve measurable results and drive growth for your business.</p>
              <button onClick={handleClickContact}>Contact Us</button>
            </div>
            <div className="seo-banner-image">
              <img src={seoImage} alt="Marketplace Management Banner" />
            </div>
          </div>
        </section>
      </div>

      <div className="seo-our-seo-container">
        <h2 className="seo-our-seo-title">Our Marketplace Management Services</h2>
        <p className="seo-our-seo-subtitle">Increase Your Sales and Get Discovered More Online</p>
        <div className="seo-our-seo-content">
          {services.map((service, index) => (
            <div className="seo-our-seo-item" key={index}>
              <FontAwesomeIcon icon={service.icon} className="seo-our-seo-icon" />
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="seo-we-are-best-container">
        <h2 className="seo-we-are-best-title">Why Advance Techno's Marketplace Management Services Are Better Than the Rest</h2>
        <p className="seo-we-are-best-subtitle">Our Solutions Work: We’ve Proven it Over and Over Again</p>
        <div className="seo-we-are-best-content">
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faChartLine} className="seo-we-are-best-icon animated-icon" />
            <h3>Experience Matters</h3>
            <p>
              For more than 10 years, Advance Techno has helped businesses in all industries grow their online presence to drive more revenue. We have the experience and client success stories to support any size business wanting to increase online traffic and grow its leads. Many of our experts have been industry leaders for more than a decade. Our team collaborates. If someone has an issue, we all jump in.
            </p>
          </div>
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faUserTie} className="seo-we-are-best-icon animated-icon" />
            <h3>Dedicated Professionals</h3>
            <p>
              We believe our job is to make a technical, complicated, and sometimes overwhelming marketplace management process as simple as possible for our clients. One of the many ways we accomplish this is by providing each client with a single point of contact to manage your project. You get to know your Advance Techno professional well and they will get to know your business and understand your unique needs.
            </p>
          </div>
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faHandshake} className="seo-we-are-best-icon animated-icon" />
            <h3>Relationships & Results</h3>
            <p>
              We live by our two primary core values: Relationships and Results. We’re here for every client, that’s our pillar. We have marketplace experts who specialize in helping businesses of all sizes—from small businesses to large, national franchises—achieve exponential growth. Our marketplace management company believes in owning the voice of our clients and we work hard to prove it.
            </p>
          </div>
        </div>
      </div>

      <div className="embrace-seo-container">
        <h2 className="embrace-seo-heading">
          Redefine Possibilities – Embrace SEO with Advance Techno!
        </h2>
        <p className="embrace-seo-subheading">Domains We Cater To</p>
        <div className="embrace-seo-domains">
          {domains.map((domain, index) => (
            <div
              key={index}
              className={`embrace-seo-domain-item ${domain.dark ? 'embrace-seo-dark' : 'embrace-seo-light'}`}
            >
              <h3 className="embrace-seo-domain-title">{domain.title}</h3>
              <p className="embrace-seo-domain-description">{domain.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="testimonials-container">
        <h1>What Our Clients Say About Us</h1>
      <div className="testimonials-inner">
        {testimonials.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
            <h3>{testimonial.name}</h3>
            <div className="rating">
              {'★'.repeat(testimonial.rating)}
            </div>
            <p>{testimonial.text}</p>
          </div>
        ))}
      </div>
    </div>

      <CallToAction />
      <ContactForm/>

      {/* <div className="seo-bottom-banner">
        <h2 className="seo-bottom-banner-title">Advance Techno Offers the Following Marketplace Management Services:</h2>
        <div className="seo-bottom-banner-icons">
          <Link to="/facebook_ads"><img src={facebookLogo} alt="Facebook" /></Link>
          <Link to="/instagram_ads"><img src={instagramLogo} alt="Instagram" /></Link>
          <Link to="/google_ads"><img src={googleLogo} alt="Google" /></Link>
          <Link to="/snapchat_ads"><img src={snapchatLogo} alt="Snapchat" /></Link>
          <Link to="/tiktok_ads"><img src={tiktokLogo} alt="TikTok" /></Link>
          <Link to="/linkedin_ads"><img src={linkedinLogo} alt="LinkedIn" /></Link>
        </div>
      </div> */}
    </div>
  );
}
