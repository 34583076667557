import React from 'react';
import bannerSEO from '../../media/bannerseo.webp';
import seoImage from '../../media/leadgen.webp';
import '../AppDevelopment/AppDevelopment.css';
import './VideoEditing.css'
import CallToAction from '../CallToAction/CallToAction';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faUserTie, faHandshake, faRocket, faGavel, faClipboardCheck, 
        faSearch, faLink, faCog, faMapMarkerAlt, faFileAlt, 
        faBoxOpen, faShoppingCart, faPencilAlt, 
        faTachometerAlt, faCogs, faStore} from '@fortawesome/free-solid-svg-icons';
        import ContactForm from '../ContactForm/ContactForm';

import p1 from '../../media/p3.webp'
import p2 from '../../media/p1.webp'
import p3 from '../../media/p5.webp'
import p4 from '../../media/p6.webp'
import p5 from '../../media/p2.webp'


export default function SEO() {

  const navigate = useNavigate();

  const services = [
    {
      icon: faSearch,
      title: 'Keyword Research & Strategy',
      description: 'We identify the most relevant and high-traffic keywords to target, ensuring your content reaches the right audience and drives qualified leads.'
    },
    {
      icon: faLink,
      title: 'Link Building',
      description: 'Our team builds high-quality backlinks to your site, improving your search rankings and driving more organic traffic and leads.'
    },
    {
      icon: faCog,
      title: 'Technical SEO',
      description: 'Optimize your website’s backend to enhance site speed and performance, making it easier for search engines to index your site and improve lead generation.'
    },
    {
      icon: faMapMarkerAlt,
      title: 'Local SEO',
      description: 'Boost your local presence with Google My Business optimization and attract high-intent local customers to generate leads from your immediate area.'
    },
    {
      icon: faFileAlt,
      title: 'On-Page SEO',
      description: 'Improve your online visibility and attract high-quality traffic by optimizing your website’s content, headlines, and HTML tags for better lead generation.'
    },
    {
      icon: faChartLine,
      title: 'Off-Page SEO',
      description: 'Enhance your brand’s credibility and increase online exposure through strategic off-page SEO tactics like social media, link building, and influencer marketing.'
    },
    {
      icon: faCogs,
      title: 'Franchise SEO',
      description: 'Maximize your franchise’s impact and increase leads and sales with geo-specific content, custom web designs, and local SEO strategies.'
    },
    {
      icon: faStore,
      title: 'Shopify SEO',
      description: 'Strengthen your online store’s presence with specialized SEO audits, web design, and paid advertising to drive more leads and sales.'
    },
    {
      icon: faShoppingCart,
      title: 'eCommerce SEO',
      description: 'Enhance your eCommerce site’s visibility and drive more leads and sales through our tailored SEO strategies and Walmart Marketplace integrations.'
    },
    {
      icon: faPencilAlt,
      title: 'Content Writing',
      description: 'Create engaging, SEO-friendly content that attracts and converts visitors into leads through share-worthy blog posts and optimized web pages.'
    },
    {
      icon: faTachometerAlt,
      title: 'Conversion Rate Optimization (CRO)',
      description: 'Boost your conversion rates and online revenue with targeted CRO strategies, including website analysis, landing page optimization, and A/B testing.'
    },
    {
      icon: faBoxOpen,
      title: 'Amazon SEO',
      description: 'Differentiate your brand on Amazon with optimized product listings and goal-oriented marketing strategies to drive more sales and leads.'
    }
  ];

  const handleClickContact = () => {
    navigate(`/contact-us-at-advance-techno`);
  };
  
  const domains = [
    {
      title: 'E-commerce',
      description: 'We specialize in driving organic traffic and maximizing revenue for online stores through targeted SEO campaigns, ensuring a steady flow of high-quality leads.',
      dark: true,
    },
    {
      title: 'B2B Services',
      description: 'Our customized SEO strategies are designed to generate leads by attracting and engaging decision-makers within businesses, making us the best in B2B lead generation.',
      dark: false,
    },
    {
      title: 'Hospitality and Tourism',
      description: 'We tailor SEO campaigns to your target audience, helping you establish your business as a respected authority and generating a higher volume of leads in the hospitality sector.',
      dark: true,
    },
    {
      title: 'Healthcare',
      description: 'Our specialized SEO solutions, including local search optimization and content marketing, are aimed at attracting and engaging patients, making us leaders in healthcare lead generation.',
      dark: false,
    },
    {
      title: 'Education',
      description: 'We employ targeted SEO tactics to reach prospective students, optimizing program pages and advertising campaigns to generate valuable leads in the education sector.',
      dark: false,
    },
    {
      title: 'Real Estate',
      description: 'Our strategies, such as targeted advertising and content marketing, showcase listings and expertise, driving more leads and making us experts in real estate lead generation.',
      dark: true,
    },
    {
      title: 'Retail',
      description: 'We drive both online and offline sales for retail businesses with omnichannel SEO strategies, optimizing for local SEO and social media advertising to generate quality leads.',
      dark: false,
    },
    {
      title: 'Technology',
      description: 'Our targeted advertising and content marketing efforts help technology companies increase brand visibility, generate leads, and drive product adoption, making us the best in tech lead generation.',
      dark: true,
    },
  ];
  
  const testimonials = [
    {
      name: 'Aditya',
      rating: 5,
      text: 'The lead generation service by Advance Techno is outstanding! The team went above and beyond to ensure we achieved the best search rankings and organic traffic. Highly recommended!',
      image: p1 // replace with actual image paths
    },
    {
      name: 'Sakshi',
      rating: 5,
      text: 'Absolutely fantastic experience from start to finish. The professionalism and attention to detail in optimizing our website for lead generation were top-notch.',
      image: p2
    },
    {
      name: 'Rohit',
      rating: 5,
      text: 'Very satisfied with the results. The lead generation service was impeccable, and the team helped us achieve incredible growth in organic traffic.',
      image: p3
    },
    {
      name: 'Priya',
      rating: 5,
      text: 'Exceeded my expectations in every way. The quality of service and the dedication to improving our search engine rankings and lead generation are unmatched.',
      image: p5
    }
  ];

  return (
    <div>
      <div className="homepage-banner">
        <img src={bannerSEO} alt="SEO Banner" className="homepage-banner-image" />
        <div className="homepage-banner-content">
          <h1>Boost Your Lead Generation with Advance Techno's Expert SEO Services</h1>
          <p>Empowering Brands, Amplifying Reach, and Driving Success with Cutting-Edge Lead Generation Strategies</p>
          <p>-<strong>Welcome to Advance Techno</strong></p>
          <button className="homepage-cta-button" onClick={handleClickContact}>Set Up A Call</button>
        </div>
      </div>

      <section>
        <div className="website-design-dev-container">
          <h1 className="website-design-dev-title">
            Lead Generation <span className="highlight">SERVICES</span>
          </h1>
        </div>
      </section>

      <div className='homepage'>
        <section>
          <div className="seo-banner">
            <div className="seo-banner-content">
              <h1 className="seo-banner-heading">Expert Lead Generation Services</h1>
              <p>Welcome to Advance Techno's lead generation services page. We specialize in crafting high-impact lead generation campaigns tailored to your business goals. Let us help you achieve measurable results and drive growth for your business.</p>
              <button onClick={handleClickContact}>Contact Us</button>
            </div>
            <div className="seo-banner-image">
              <img src={seoImage} alt="SEO Banner" />
            </div>
          </div>
        </section>
      </div>

      <div className="seo-our-seo-container">
        <h2 className="seo-our-seo-title">Our Lead Generation Services</h2>
        <p className="seo-our-seo-subtitle">Increase Your Leads and Get Discovered More Online</p>
        <div className="seo-our-seo-content">
          {services.map((service, index) => (
            <div className="seo-our-seo-item" key={index}>
              <FontAwesomeIcon icon={service.icon} className="seo-our-seo-icon" />
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="seo-we-are-best-container">
        <h2 className="seo-we-are-best-title">Why Advance Techno's Lead Generation Services Are Better Than the Rest</h2>
        <div className="seo-we-are-best-content">
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faUserTie} className="seo-we-are-best-icon" />
            <h3>Experienced Professionals</h3>
            <p>Our team comprises industry experts with years of experience in lead generation and digital marketing.</p>
          </div>
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faHandshake} className="seo-we-are-best-icon" />
            <h3>Client-Centric Approach</h3>
            <p>We prioritize your business goals and tailor our strategies to meet your specific lead generation needs.</p>
          </div>
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faRocket} className="seo-we-are-best-icon" />
            <h3>Results-Driven Strategies</h3>
            <p>Our proven strategies are designed to deliver tangible results, increasing your leads and boosting your revenue.</p>
          </div>
        </div>
      </div>

      <div className="seo-domains-container">
        <h2 className="seo-domains-title">Industries We Serve</h2>
        <div className="seo-domains-content">
          {domains.map((domain, index) => (
            <div className={`seo-domain-item ${domain.dark ? 'dark' : 'light'}`} key={index}>
              <h3>{domain.title}</h3>
              <p>{domain.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="seo-testimonials-container">
        <h2 className="seo-testimonials-title">What Our Clients Say</h2>
        <div className="seo-testimonials-content">
          {testimonials.map((testimonial, index) => (
            <div className="seo-testimonial-item" key={index}>
              <img src={testimonial.image} alt={testimonial.name} className="seo-testimonial-image" />
              <div className="seo-testimonial-text">
                <h3>{testimonial.name}</h3>
                <p>{'⭐'.repeat(testimonial.rating)}</p>
                <p>{testimonial.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>



      <CallToAction />
      <ContactForm/>

    </div>
  );
}
