import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h2>Privacy Policy for Advance Techno</h2>
      
      <section>
        <h3>Introduction</h3>
        <p>
          Welcome to Advance Techno! We are committed to safeguarding your privacy and ensuring that your personal information is protected. This Privacy Policy outlines how we collect, use, and protect your data when you visit our website and use our services. By using our website and services, you agree to the terms outlined in this policy.
        </p>
      </section>

      <section>
        <h3>Information We Collect</h3>
        <h4>Personal Information</h4>
        <p>
          When you interact with Advance Techno, we may collect personal information, including but not limited to:
        </p>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Billing information</li>
          <li>Job title and company information</li>
          <li>Training preferences and feedback</li>
        </ul>

        <h4>Non-Personal Information</h4>
        <p>
          We also collect non-personal information, such as:
        </p>
        <ul>
          <li>Browser type</li>
          <li>IP address</li>
          <li>Pages visited</li>
          <li>Time spent on the website</li>
          <li>Referring website</li>
        </ul>
      </section>

      <section>
        <h3>How We Use Your Information</h3>
        <h4>Providing Services</h4>
        <p>
          We use the information we collect to:
        </p>
        <ul>
          <li>Provide and manage our digital marketing services</li>
          <li>Offer IT training programs</li>
          <li>Process payments and manage accounts</li>
          <li>Communicate with you about updates, promotions, and events</li>
          <li>Improve our services and website functionality</li>
        </ul>

        <h4>Marketing and Communication</h4>
        <p>
          With your consent, we may use your personal information to:
        </p>
        <ul>
          <li>Send you newsletters, promotional materials, and other information related to our services</li>
          <li>Personalize your experience on our website</li>
          <li>Conduct surveys and gather feedback</li>
        </ul>

        <h4>Legal Obligations</h4>
        <p>
          We may disclose your information if required by law or in response to legal processes.
        </p>
      </section>

      <section>
        <h3>Data Security</h3>
        <p>
          We implement a variety of security measures to ensure the safety of your personal information. These measures include:
        </p>
        <ul>
          <li>Encryption of sensitive data</li>
          <li>Secure access controls</li>
          <li>Regular security audits and updates</li>
        </ul>
      </section>

      <section>
        <h3>Cookies and Tracking Technologies</h3>
        <p>
          Our website uses cookies and similar tracking technologies to enhance your experience. Cookies are small data files stored on your device. They help us understand how you use our website and improve its functionality.
        </p>
        <p>
          You can control cookie settings through your browser. However, disabling cookies may affect your ability to use certain features of our website.
        </p>
      </section>

      <section>
        <h3>Third-Party Services</h3>
        <p>
          We may share your information with third-party service providers to facilitate our services, such as:
        </p>
        <ul>
          <li>Payment processors</li>
          <li>Email marketing platforms</li>
          <li>Analytics providers</li>
        </ul>
        <p>
          These third parties are obligated to protect your information and use it solely for the purposes specified by Advance Techno.
        </p>
      </section>

      <section>
        <h3>Data Retention</h3>
        <p>
          We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.
        </p>
      </section>

      <section>
        <h3>Your Rights</h3>
        <p>
          You have the right to:
        </p>
        <ul>
          <li>Access and update your personal information</li>
          <li>Request the deletion of your personal information</li>
          <li>Opt-out of receiving marketing communications</li>
          <li>Restrict the processing of your personal information</li>
          <li>Object to the processing of your personal information</li>
          <li>Data portability</li>
        </ul>
        <p>
          To exercise these rights, please contact us using the information provided below.
        </p>
      </section>

      <section>
        <h3>Changes to This Privacy Policy</h3>
        <p>
          Advance Techno may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website. You are advised to review this Privacy Policy periodically for any changes.
        </p>
      </section>

      <section>
        <h3>Contact Us</h3>
        <p>
          If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
        </p>
        <address>
          Advance Techno<br />
          Email: <a href="mailto:info@advancetechno.org">info@advancetechno.org</a><br />
          Phone: +91 7976289796<br />
          Address: A-804, Ashirwad Gokul Tower, Ajay Ahuja Nagar, Kota-324010
        </address>
      </section>

      <p>Thank you for trusting Advance Techno with your personal information. We are dedicated to protecting your privacy and providing you with exceptional service.</p>
    </div>
  );
}

export default PrivacyPolicy;
