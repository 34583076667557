import React from 'react';

const MapComponent = () => {
  return (
    <div style={{ width: '100%', height: '400px', position: 'relative' }}>
      <iframe
        title="Google Map"
        width="100%"
        height="100%"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.597247075654!2d75.84302679999999!3d25.115491400000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396f85026a265c73%3A0x8100a4eef97e6eb1!2sAdvance%20Techno!5e0!3m2!1sen!2sin!4v1717281204915!5m2!1sen!2sin"
        style={{ border: 0 }}
      ></iframe>
    </div>
  );
};

export default MapComponent;
