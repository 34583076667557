import React from 'react';
import bannerGraphics from '../../media/bannergraph.webp'; // Update the image source
import graphicsImage from '../../media/graph.webp'; // Update the image source
import '../AppDevelopment/AppDevelopment.css';
import facebookLogo from '../../media/fb.webp';
import instagramLogo from '../../media/ig.webp';
import googleLogo from '../../media/google.webp';
import snapchatLogo from '../../media/sc.webp';
import tiktokLogo from '../../media/tt.webp';
import linkedinLogo from '../../media/ln.webp';
import CallToAction from '../CallToAction/CallToAction';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette, faPenNib, faVectorSquare, faBrush, faImage, faEye, 
        faPrint, faAd, faLaptop, faPencilRule, faHandshake, faPencilRuler } from '@fortawesome/free-solid-svg-icons';

import p1 from '../../media/p3.webp';
import p2 from '../../media/p1.webp';
import p3 from '../../media/p5.webp';
import p4 from '../../media/p6.webp';
import p5 from '../../media/p2.webp';
import ContactForm from '../ContactForm/ContactForm';


export default function GraphicsDesigning() {

  const navigate = useNavigate();

  const services = [
    {
      icon: faPalette,
      title: 'Brand Identity Design',
      description: 'Create a strong and cohesive brand identity with our comprehensive design services, including logos, color schemes, typography, and brand guidelines.'
    },
    {
      icon: faPenNib,
      title: 'Logo Design',
      description: 'Our team designs unique and memorable logos that capture the essence of your brand and make a lasting impression on your audience.'
    },
    {
      icon: faVectorSquare,
      title: 'Vector Illustrations',
      description: 'Enhance your visual content with custom vector illustrations that are scalable, versatile, and perfect for any medium.'
    },
    {
      icon: faBrush,
      title: 'Digital Art & Illustration',
      description: 'Our digital artists create stunning illustrations and digital art pieces that bring your ideas to life and captivate your audience.'
    },
    {
      icon: faImage,
      title: 'Graphic Design for Print',
      description: 'From brochures to business cards, we design eye-catching print materials that effectively communicate your message and reinforce your brand identity.'
    },
    {
      icon: faEye,
      title: 'Visual Content Creation',
      description: 'Our visual content creation services include everything from social media graphics to infographics, ensuring your content is engaging and shareable.'
    },
    {
      icon: faPrint,
      title: 'Packaging Design',
      description: 'We create attractive and functional packaging designs that not only protect your product but also make it stand out on the shelves.'
    },
    {
      icon: faAd,
      title: 'Advertising Design',
      description: 'Our advertising design services help you create compelling ads for both digital and print media, driving engagement and conversions.'
    },
    {
      icon: faLaptop,
      title: 'Web & Mobile Design',
      description: 'We design user-friendly and visually appealing websites and mobile apps that provide an excellent user experience and drive user engagement.'
    },
    {
      icon: faPencilRuler,
      title: 'Custom Illustrations',
      description: 'Our custom illustration services provide you with unique and tailored artwork that perfectly fits your brand and message.'
    }
  ];
  
  const handleClickContact = () => {
    navigate(`/contact-us-at-advance-techno`);
  };

  const domains = [
    {
      title: 'E-commerce',
      description: 'Advance Techno creates stunning visual content and branding for e-commerce businesses to drive engagement and boost sales.',
      dark: true,
    },
    {
      title: 'B2B Services',
      description: 'We design professional and impactful graphics for B2B companies, enhancing their corporate identity and marketing materials.',
      dark: false,
    },
    {
      title: 'Healthcare',
      description: 'Our team develops visually appealing and informative graphics for healthcare organizations, improving patient communication and brand presence.',
      dark: true,
    },
    {
      title: 'Retail',
      description: 'Advance Techno designs eye-catching retail graphics, including in-store displays and promotional materials, to attract customers and increase sales.',
      dark: false,
    },
    {
      title: 'Technology',
      description: 'We create sleek and modern graphics for technology companies, enhancing their digital presence and marketing efforts.',
      dark: true,
    },
    {
      title: 'Real Estate',
      description: 'Advance Techno provides real estate businesses with high-quality visual content, including property brochures and digital ads, to showcase their listings effectively.',
      dark: false,
    },
    {
      title: 'Education',
      description: 'Our team designs engaging and informative graphics for educational institutions, helping them attract and retain students.',
      dark: true,
    },
    {
      title: 'Automotive',
      description: 'We offer graphic design services for automotive businesses, including advertising materials and branding, to drive visibility and sales.',
      dark: false,
    },
  ];
  

  const testimonials = [
    {
      name: 'Aditya',
      rating: 5,
      text: 'The graphic design service by Advance Techno is outstanding! The team went above and beyond to create stunning visuals that represent our brand perfectly. Highly recommended!',
      image: p1 // replace with actual image paths
    },
    {
      name: 'Sakshi',
      rating: 5,
      text: 'Absolutely fantastic experience from start to finish. The professionalism and creativity in designing our marketing materials were top-notch.',
      image: p2
    },
    // {
    //   name: 'Rohit',
    //   rating: 5,
    //   text: 'Very satisfied with the results. The graphic design service was impeccable, and the team helped us achieve incredible growth in our brand presence.',
    //   image: bannerGraphics
    // },
    {
      name: 'Priya',
      rating: 5,
      text: 'Exceeded my expectations in every way. The quality of design and the dedication to improving our visual content are unmatched.',
      image: p5
    },
    {
      name: 'Vikram',
      rating: 5,
      text: 'Outstanding service and support! Our brand visuals have never looked better thanks to their strategic design efforts.',
      image: p4
    },

  ];

  return (
    <div>
      <div className="homepage-banner">
        <img src={bannerGraphics} alt="Graphics Designing Banner" className="homepage-banner-image" />
        <div className="homepage-banner-content">
          <h1>Enhance Your Brand with Advance Techno's Expert Graphics Designing Services</h1>
          <p>Creating Stunning Visuals, Strengthening Brands, and Driving Success with Creative Designs</p>
          <p>-<strong>Welcome to Advance Techno</strong></p>
          <button className="homepage-cta-button" onClick={handleClickContact}>Set Up A Call</button>
        </div>
      </div>

      <section>
        <div className="website-design-dev-container">
          <h1 className="website-design-dev-title">
            Graphics <span className="highlight">DESIGNING SERVICES</span>
          </h1>
        </div>
      </section>

      <div className='homepage'>
        <section>
          <div className="seo-banner">
            <div className="seo-banner-content">
              <h1 className="seo-banner-heading">Expert Graphics Designing Services</h1>
              <p>Welcome to Advance Techno's graphics designing services page. We specialize in crafting high-impact visual content tailored to your business goals. Let us help you achieve measurable results and drive growth for your business.</p>
              <button onClick={handleClickContact}>Contact Us</button>
            </div>
            <div className="seo-banner-image">
              <img src={graphicsImage} alt="Graphics Designing Banner" />
            </div>
          </div>
        </section>
      </div>

      <div className="seo-our-seo-container">
        <h2 className="seo-our-seo-title">Our Graphics Designing Services</h2>
        <p className="seo-our-seo-subtitle">Enhance Your Brand and Engage Your Audience</p>
        <div className="seo-our-seo-content">
          {services.map((service, index) => (
            <div className="seo-our-seo-item" key={index}>
              <FontAwesomeIcon icon={service.icon} className="seo-our-seo-icon" />
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="seo-we-are-best-container">
        <h2 className="seo-we-are-best-title">Why Advance Techno's Graphics Designing Services Are Better Than the Rest</h2>
        <p className="seo-we-are-best-subtitle">Our Designs Work: We’ve Proven it Over and Over Again</p>
        <div className="seo-we-are-best-content">
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faPalette} className="seo-we-are-best-icon animated-icon" />
            <h3>Experience Matters</h3>
            <p>
              For more than 10 years, Advance Techno has helped businesses in all industries enhance their visual presence to drive more engagement. We have the experience and client success stories to support any size business wanting to improve their brand's visuals. Many of our experts have been industry leaders for more than a decade. Our team collaborates. If someone has an issue, we all jump in.
            </p>
          </div>
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faPenNib} className="seo-we-are-best-icon animated-icon" />
            <h3>Dedicated Professionals</h3>
            <p>
              We believe our job is to make a technical, complicated, and sometimes overwhelming graphics designing process as simple as possible for our clients. One of the many ways we accomplish this is by providing each client with a single point of contact to manage your project. You get to know your Advance Techno professional well and they will get to know your business and understand your unique needs.
            </p>
          </div>
          <div className="seo-we-are-best-item">
            <FontAwesomeIcon icon={faHandshake} className="seo-we-are-best-icon animated-icon" />
            <h3>Relationships & Results</h3>
            <p>
              We live by our two primary core values: Relationships and Results. We’re here for every client, that’s our pillar. We have graphics designing experts who specialize in helping businesses of all sizes—from small businesses to large, national franchises—achieve exponential growth. Our graphics designing company believes in owning the voice of our clients and we work hard to prove it.
            </p>
          </div>
        </div>
      </div>

      <div className="embrace-seo-container">
        <h2 className="embrace-seo-heading">
          Redefine Possibilities – Embrace Graphics Designing with Advance Techno!
        </h2>
        <p className="embrace-seo-subheading">Domains We Cater To</p>
        <div className="embrace-seo-domains">
          {domains.map((domain, index) => (
            <div
              key={index}
              className={`embrace-seo-domain-item ${domain.dark ? 'embrace-seo-dark' : 'embrace-seo-light'}`}
            >
              <h3 className="embrace-seo-domain-title">{domain.title}</h3>
              <p className="embrace-seo-domain-description">{domain.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="testimonials-container">
        <h1>What Our Clients Say About Us</h1>
      <div className="testimonials-inner">
        {testimonials.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
            <h3>{testimonial.name}</h3>
            <div className="rating">
              {'★'.repeat(testimonial.rating)}
            </div>
            <p>{testimonial.text}</p>
          </div>
        ))}
      </div>
    </div>

      <CallToAction />
      <ContactForm />

      {/* <div className="seo-bottom-banner">
        <h2 className="seo-bottom-banner-title">Advance Techno Offers the Following Graphics Designing Services:</h2>
        <div className="seo-bottom-banner-icons">
          <Link to="/facebook_ads"><img src={facebookLogo} alt="Facebook" /></Link>
          <Link to="/instagram_ads"><img src={instagramLogo} alt="Instagram" /></Link>
          <Link to="/google_ads"><img src={googleLogo} alt="Google" /></Link>
          <Link to="/snapchat_ads"><img src={snapchatLogo} alt="Snapchat" /></Link>
          <Link to="/tiktok_ads"><img src={tiktokLogo} alt="TikTok" /></Link>
          <Link to="/linkedin_ads"><img src={linkedinLogo} alt="LinkedIn" /></Link>
        </div>
      </div> */}
    </div>
  );
}
