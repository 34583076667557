import React from 'react';
import bannerSEO from '../../media/worktop.jpg';
import '../Work/Work.css';
import CallToAction from '../CallToAction/CallToAction';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faHeart,faSearch, faSmile, faUsers,faLightbulb, faHandsHelping, faAward, faStar, faRocket, faHandshake, faShieldAlt  } from '@fortawesome/free-solid-svg-icons';

import b1 from '../../media/logo1/l1 (1).jpeg'
import b2 from '../../media/logo1/l1 (2).jpeg'
import b3 from '../../media/logo1/l1 (3).jpeg'
import b4 from '../../media/logo1/l1 (4).jpeg'
import b5 from'../../media/logo1/l1 (5).jpeg'
import b6 from '../../media/logo1/l1 (6).jpeg'
import b7 from '../../media/logo1/l1 (7).jpeg'
import b8 from '../../media/logo1/l1 (8).jpeg'
import b9 from '../../media/logo1/l1 (9).jpeg'
import b10 from'../../media/logo1/l1 (10).jpeg'
import b11 from '../../media/logss/dfd.png'
import b12 from '../../media/logo1/l1 (11).jpeg'
import b13 from '../../media/logo1/l1 (12).jpeg'
import b14 from '../../media/logo1/l1 (13).jpeg'
import b15 from '../../media/logo1/l1 (14).jpeg'
import b16 from '../../media/logo1/l1 (15).jpeg'
import b17 from '../../media/logo1/l1 (16).jpeg'
import b18 from '../../media/logo1/l1 (17).jpeg'
import b19 from '../../media/logo1/l1 (1).jpeg'




import p1 from '../../media/p3.jpg'
import p2 from '../../media/p1.jpg'
import p3 from '../../media/p5.jpg'
import ContactForm from '../ContactForm/ContactForm';



const Work = () => {
  const navigate = useNavigate();

  const services = [
    {
      icon: faSearch,
      title: 'Commitment to Excellence',
      description: 'Advance Techno is dedicated to delivering the highest quality services in the industry. Our team of experts constantly pushes the boundaries to ensure that we exceed our clients\' expectations. From initial research to final execution, excellence is our standard.'
    },
    {
      icon: faSmile,
      title: 'Client Satisfaction',
      description: 'Our clients love our work, and it shows. With a customer-centric approach, we tailor our strategies to meet the unique needs of each client. Our goal is to build long-term relationships by consistently delivering outstanding results and exceptional customer service.'
    },
    {
      icon: faUsers,
      title: 'Superior Team Spirit',
      description: 'The spirit of collaboration and innovation thrives at Advance Techno. Our team is composed of passionate professionals who are dedicated to achieving the best outcomes. We foster a positive work environment where creativity and teamwork drive our success.'
    },
    {
      icon: faChartLine,
      title: 'Unmatched Performance',
      description: 'Advance Techno stands out as the best agency you can get. Our proven track record of success, combined with our cutting-edge techniques and tools, ensures that we deliver results that are unmatched in the industry. We are committed to helping our clients achieve their goals and grow their businesses.'
    },
    {
      icon: faLightbulb,
      title: 'Innovative Solutions',
      description: 'Innovation is at the heart of what we do. We are constantly exploring new ideas and technologies to provide our clients with innovative solutions that give them a competitive edge. Our forward-thinking approach ensures that we stay ahead of the curve and deliver top-notch services.'
    },
    {
      icon: faHandsHelping,
      title: 'Collaborative Partnership',
      description: 'We believe in working closely with our clients to understand their needs and goals. Our collaborative partnership approach ensures that we are aligned with our clients\' vision and can deliver customized solutions that drive success. Your success is our success.'
    },
    {
      icon: faAward,
      title: 'Award-Winning Services',
      description: 'Our dedication to excellence has earned us numerous awards and accolades. We are proud of the recognition we have received for our outstanding work and are committed to maintaining the highest standards in everything we do. Partner with us to experience award-winning services.'
    },
    {
      icon: faStar,
      title: 'Exceptional Quality',
      description: 'Quality is paramount at Advance Techno. We adhere to the highest standards of quality in every project we undertake. Our meticulous attention to detail and commitment to delivering superior quality ensures that our clients receive the best possible outcomes.'
    },
    {
      icon: faRocket,
      title: 'Proven Results',
      description: 'Our strategies are backed by data and driven by results. We have a proven track record of helping our clients achieve their business goals through effective and innovative digital marketing solutions. Trust us to deliver results that make a significant impact on your business.'
    },
    {
      icon: faHandshake,
      title: 'Trust and Reliability',
      description: 'Building trust and reliability is at the core of our business. Our clients can count on us to deliver on our promises and provide consistent, reliable services. We are committed to earning your trust and becoming a valued partner in your business success.'
    },
    {
      icon: faHeart,
      title: 'Passion for Excellence',
      description: 'Our passion for excellence drives everything we do. We are dedicated to continuously improving our skills and staying updated with the latest industry trends to ensure that we provide the best possible services to our clients. Our passion is reflected in the outstanding results we deliver.'
    },
    {
      icon: faShieldAlt,
      title: 'Security and Confidentiality',
      description: 'At Advance Techno, we prioritize the security and confidentiality of our clients\' data. We adhere to strict security protocols to safeguard your sensitive information and ensure compliance with data protection regulations. You can trust us to handle your data with the utmost care and confidentiality.'
    }
    
  ];
  

  const testimonials = [
    {
      image: p1,
      name: 'Aditya',
      rating: 5,
      text: 'Advance Techno significantly improved our online visibility and boosted our sales. Their team is professional and results-driven.'
    },
    {
      image: p2,
      name: 'Priya',
      rating: 4,
      text: 'The SEO strategies implemented by Advance Techno were exactly what our business needed. We saw a substantial increase in organic traffic.'
    },
    {
      image: p3,
      name: 'Manish',
      rating: 5,
      text: 'Working with Advance Techno has been a game-changer. Their expertise and commitment to our success are unmatched.'
    },
  ];

  const brands = [
    { logo: b1 },
    { logo: b2 },
    { logo: b3 },
    { logo: b4 },
    { logo: b5 },
    { logo: b6 },
    { logo: b7 },
    { logo: b8 },
    { logo: b9 },
    { logo: b10 },

    { logo: b12 },
    { logo: b13 },
    { logo: b14 },
    { logo: b15 },
    { logo: b16 },
    { logo: b17 },
    { logo: b18 },
    { logo: b19 },

  ];

  const handleClickContact = () => {
    navigate('/contact');
  };

  return (
    <div className="our-work">


<div className="homepage-banner">
        <img src={bannerSEO} alt="Branding Banner" className="homepage-banner-image" />
        <div className="homepage-banner-content">
        <h1>Our Success Stories with Advance Techno</h1>
          <p>Empowering Brands, Amplifying Reach, and Driving Success with Cutting-Edge Strategies</p>
          <button className="homepage-cta-button" onClick={handleClickContact}>Set Up A Call</button>
        </div>
      </div>






      <div className="seo-our-seo-container">
        <h2 className="seo-our-seo-title">Our Services</h2>
        <p className="seo-our-seo-subtitle">Advance Techno offers a comprehensive range of SEO services tailored to your business needs.</p>
        <div className="seo-our-seo-content">
          {services.map((service, index) => (
            <div className="seo-our-seo-item" key={index}>
              <FontAwesomeIcon icon={service.icon} className="seo-our-seo-icon" />
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>

      <section className="case-studies">
        <h2>Case Studies</h2>
        <div className="case-study-list">
          <div className="case-study-item">
            <h3>Boosting E-commerce Sales</h3>
            <p>How we helped an online retailer increase their organic traffic by 50% in six months.</p>
          </div>
          <div className="case-study-item">
            <h3>Improving Local SEO for Healthcare</h3>
            <p>Our strategies led to a 40% increase in new patient appointments for a healthcare provider.</p>
          </div>
          {/* Add more case studies as needed */}
        </div>
      </section>

      <section className="brands-we-work-with">
        <h2>Brands We Work With</h2>
        <div className="brands-list">
          {brands.map((brand, index) => (
            <div className="brand-item" key={index}>
              <img src={brand.logo} alt={`${brand.name} Logo`} className="brand-logo" />
              <p>{brand.name}</p>
            </div>
          ))}
        </div>
      </section>

      <div className="testimonials-container">
  <h1>What Our Clients Say About Us</h1>
  <div className="testimonials-inner">
    {testimonials.map((testimonial, index) => (
      <div className="testimonial-card" key={index}>
        <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
        <h3>{testimonial.name}</h3>
        <div className="rating">
          {'★'.repeat(testimonial.rating)}
        </div>
        <p>{testimonial.text}</p>
      </div>
    ))}
  </div>
</div>

      <CallToAction />
      <ContactForm/>
    </div>
  );
};

export default Work;
