import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h2>Terms and Conditions for Advance Techno</h2>
      
      <section>
        <h3>Introduction</h3>
        <p>
          Welcome to Advance Techno! These Terms and Conditions ("Terms") govern your use of our website and services. By accessing or using our website or services, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please do not use our website or services.
        </p>
      </section>

      <section>
        <h3>Definitions</h3>
        <p>
          - "Advance Techno," "we," "us," and "our" refer to the digital marketing and IT training services provided by Advance Techno.<br />
          - "You" and "your" refer to the user or viewer of our website and services.<br />
          - "Services" refer to all services provided by Advance Techno, including digital marketing and IT training.
        </p>
      </section>

      <section>
        <h3>Use of Services</h3>
        <h4>Eligibility</h4>
        <p>
          You must be at least 18 years old to use our services. By using our services, you represent and warrant that you meet this age requirement.
        </p>

        <h4>Account Registration</h4>
        <p>
          To access certain features of our services, you may need to create an account. You agree to:
        </p>
        <ul>
          <li>Provide accurate and complete information during registration.</li>
          <li>Maintain the security of your account by not sharing your password with others.</li>
          <li>Notify us immediately of any unauthorized use of your account.</li>
        </ul>

        <h4>Prohibited Activities</h4>
        <p>
          You agree not to:
        </p>
        <ul>
          <li>Use our services for any illegal or unauthorized purposes.</li>
          <li>Interfere with or disrupt the operation of our website or services.</li>
          <li>Post or transmit any content that is offensive, defamatory, or violates any third-party rights.</li>
          <li>Attempt to gain unauthorized access to any portion of our website or services.</li>
        </ul>
      </section>

      <section>
        <h3>Intellectual Property</h3>
        <h4>Ownership</h4>
        <p>
          All content on our website, including text, graphics, logos, and software, is the property of Advance Techno or its licensors and is protected by intellectual property laws.
        </p>

        <h4>Limited License</h4>
        <p>
          We grant you a limited, non-exclusive, non-transferable license to access and use our website and services for personal and non-commercial purposes. You may not reproduce, distribute, modify, or create derivative works from our content without our prior written consent.
        </p>
      </section>

      <section>
        <h3>Payment and Refunds</h3>
        <h4>Fees</h4>
        <p>
          Certain services offered by Advance Techno may require payment of fees. All fees are in the currency specified on our website and are non-refundable unless otherwise stated.
        </p>

        <h4>Payment Terms</h4>
        <p>
          You agree to provide accurate payment information and authorize us to charge the applicable fees to your payment method. If your payment method fails, we may suspend or terminate your access to the services.
        </p>

        <h4>Refund Policy</h4>
        <p>
          Refunds, if applicable, will be processed according to our refund policy, which is available on our website or by contacting us directly.
        </p>
      </section>

      <section>
        <h3>Termination</h3>
        <p>
          We reserve the right to suspend or terminate your access to our website and services at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users or us.
        </p>
      </section>

      <section>
        <h3>Disclaimer of Warranties</h3>
        <p>
          Our website and services are provided on an "as-is" and "as-available" basis. We make no warranties, express or implied, regarding the operation or availability of our services, or the accuracy, reliability, or completeness of any content provided.
        </p>
      </section>

      <section>
        <h3>Limitation of Liability</h3>
        <p>
          To the fullest extent permitted by law, Advance Techno shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from:
        </p>
        <ul>
          <li>Your use or inability to use our services.</li>
          <li>Unauthorized access to or use of your account or data.</li>
          <li>Any content obtained from our services.</li>
        </ul>
      </section>

      <section>
        <h3>Indemnification</h3>
        <p>
          You agree to indemnify and hold Advance Techno, its affiliates, and their respective officers, directors, employees, and agents harmless from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorney's fees, arising out of or in any way connected with your use of our services or violation of these Terms.
        </p>
      </section>

      <section>
        <h3>Governing Law</h3>
        <p>
          These Terms shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of laws principles.
        </p>
      </section>

      <section>
        <h3>Changes to Terms</h3>
        <p>
          We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on our website. Your continued use of our services after the changes take effect constitutes your acceptance of the new Terms.
        </p>
      </section>

      <section>
        <h3>Contact Us</h3>
        <p>
          If you have any questions or concerns about these Terms, please contact us at:
        </p>
        <address>
          Advance Techno<br />
          Email: <a href="mailto:info@advancetechno.org">info@advancetechno.org</a><br />
          Phone: +91 7976289796<br />
          Address: A-804, Ashirwad Gokul Tower, Ajay Ahuja Nagar, Kota-324010
        </address>
      </section>

      <p>By using our website and services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</p>
    </div>
  );
}

export default TermsAndConditions;
