import React from 'react'
import './Services.css'
import banner from '../../media/servicebanner.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faMobileAlt, faShoppingCart, faSearch,faBullhorn,faLaptopCode, faVideo, faPaintBrush, faStore, faComments, } from '@fortawesome/free-solid-svg-icons';
// import { faLaptopCode, faVideo, faPaintBrush, faStore, faComments, } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import CallToAction from '../CallToAction/CallToAction';
import ContactForm from '../ContactForm/ContactForm';


export default function Services() {

  const navigate = useNavigate();


  const handleCardClickVideoEditing = () => {
    navigate(`/video-editing-advance-techno-service`);
  };



    const handleCardClickWebsite = () => {
      navigate('/website-development-advance-techno-service');
    };
  
    const handleCardClickApp = () => {
      navigate('/seo-advance-techno-service');
    };
  
    const handleCardClickPerformanceMarketing = () => {
      navigate('/performance-marketing-advance-techno-service');
    };
  
    const handleCardClickLeadGeneration = () => {
      navigate('/lead-gen-advance-techno-service');
    };
  
    const handleCardClickGraphicsDesigning = () => {
      navigate('/graphics-designing-advance-techno-service');
    };
  
    const handleCardClickEcommerceBranding = () => {
      navigate('/ecommerce-branding-advance-techno-service');
    };
  
    const handleCardClickBusinessConsultation = () => {
      navigate('/business-consultation-advance-techno-service');
    };
  
    const handleCardClickMarketplaceManagement = () => {
      navigate('/marketplace-management-advance-techno-service');
    };
  
    const handleCardClickSocialMediaHandling = () => {
      navigate('/social-media-handling-advance-techno-service');
    };
  
    const handleClickContact = () => {
      navigate(`/contact-us-at-advance-techno`);
    };



  return (


    <div>

      <div className="homepage-banner">
        <img src={banner} alt="Banner" className="homepage-banner-image" />
        <div className="homepage-banner-content">
          {/* <h1>Welcome to Advance Techno</h1> */}
          {/* <h1>Transforming Digital Dreams into Reality,</h1> */}
          <h1>The enablers of unprecedented growth for your brand..</h1>
          <p>Unlock your brand’s full potential with Advance Techno's cutting-edge digital marketing solutions. From strategy to execution, we drive success through innovation and expertise.</p>
          <button className="homepage-cta-button" onClick={handleClickContact}>Book A Call</button>
        </div>
      </div>


      <div className='heading-services'>
        <h1>Our Services</h1>

      </div>
      <section className="services-info">
        <div className="services-info-item" onClick={handleCardClickPerformanceMarketing}>
          <div className="services-info-logo">
            <FontAwesomeIcon icon={faChartLine} className="services-info-icon" />
          </div>
          <div>
            <h3 className="services-info-heading">Performance Marketing</h3>
            <p className="services-info-description">Boost your ROI with our performance marketing strategies. We optimize your campaigns to drive targeted traffic and maximize conversions.</p>
          </div>
        </div>
        <div className="services-info-item" onClick={handleCardClickWebsite}>
          <div className="services-info-logo">
            <FontAwesomeIcon icon={faLaptopCode} className="services-info-icon" />
          </div>
          <div>
            <h3 className="services-info-heading">Website Development</h3>
            <p className="services-info-description">We create stunning, responsive websites that captivate your audience and drive conversions. Our expert developers ensure seamless performance and a user-friendly experience.</p>
          </div>
        </div>
        <div className="services-info-item" onClick={handleCardClickApp}>
          <div className="services-info-logo">
            <FontAwesomeIcon icon={faSearch} className="services-info-icon" />
          </div>
          <div>
            <h3 className="services-info-heading">SEO</h3>
            <p className="services-info-description">Our expert team specializes in optimizing your website for search engines, ensuring higher visibility and increased organic traffic. We implement the latest SEO strategies to improve your search rankings, drive targeted traffic, and boost your online presence effectively.</p>
        </div>

        </div>

        <div className="services-info-item" onClick={handleCardClickVideoEditing}>
          <div className="services-info-logo">
            <FontAwesomeIcon icon={faVideo} className="services-info-icon" />
          </div>
          <div>
            <h3 className="services-info-heading">Video Editing</h3>
            <p className="services-info-description">Our professional video editing services help you create compelling video content that engages your audience and enhances your brand message.</p>
          </div>
        </div>
        <div className="services-info-item" onClick={handleCardClickGraphicsDesigning}>
          <div className="services-info-logo">
            <FontAwesomeIcon icon={faPaintBrush} className="services-info-icon" />
          </div>
          <div>
            <h3 className="services-info-heading">Graphics Designing</h3>
            <p className="services-info-description">Stand out with our creative graphic design solutions. From logos to marketing materials, we craft visually stunning designs that resonate with your audience.</p>
          </div>
        </div>
        <div className="services-info-item" onClick={handleCardClickEcommerceBranding}>
          <div className="services-info-logo">
            <FontAwesomeIcon icon={faShoppingCart} className="services-info-icon" />
          </div>
          <div>
            <h3 className="services-info-heading">Ecommerce Branding</h3>
            <p className="services-info-description">Enhance your ecommerce presence with our branding services. We create a cohesive and attractive brand identity that drives customer loyalty and boosts sales.</p>
          </div>
        </div>
        <div className="services-info-item" onClick={handleCardClickBusinessConsultation}>
          <div className="services-info-logo">
            <FontAwesomeIcon icon={faBullhorn} className="services-info-icon" />
          </div>
          <div>
            <h3 className="services-info-heading">Business Consultation</h3>
            <p className="services-info-description">Our expert consultants provide strategic insights and practical solutions to help you overcome business challenges and achieve your goals.</p>
          </div>
        </div>
        <div className="services-info-item" onClick={handleCardClickMarketplaceManagement}>
          <div className="services-info-logo">
            <FontAwesomeIcon icon={faStore} className="services-info-icon" />
          </div>
          <div>
            <h3 className="services-info-heading">Marketplace Management</h3>
            <p className="services-info-description">We manage your online marketplace presence, optimizing listings, handling customer service, and increasing your sales and visibility.</p>
          </div>
        </div>
        <div className="services-info-item" onClick={handleCardClickSocialMediaHandling}>
          <div className="services-info-logo">
            <FontAwesomeIcon icon={faComments} className="services-info-icon" />
          </div>
          <div>
            <h3 className="services-info-heading">Social Media Handling</h3>
            <p className="services-info-description">Our social media experts handle your profiles, create engaging content, and build a strong online community for your brand.</p>
          </div>
        </div>
      </section>




      <div className="ourway-container">
      <div className="ourway-content">
        <h2 className="ourway-title">OUR WAY</h2>
        <h1 className="ourway-heading">Targeting right, communicating right.</h1>
        <p className="ourway-description">
          Broadly, the entire performance of your brand narrows down to two main points: who are you conveying your message to and how are you conveying it. Advance Techno has proven expertise in answering both aforementioned questions in the best way possible.
        </p>
        <div className="ourway-circles">
          <div className="ourway-circle">
            <h3>MARKETPLACE EXPERTISE</h3>
            <p>Getting the due recognition for the brand to drive sales.</p>
          </div>
          <div className="ourway-circle">
            <h3>SEO</h3>
            <p>Acing the game of earned media to keep you up in the charts.</p>
          </div>
          <div className="ourway-circle">
            <h3>PERFORMANCE MEDIA</h3>
            <p>Opening an all-new gateway of new audiences with new possibilities.</p>
          </div>
          <div className="ourway-circle">
            <h3>SOCIAL MEDIA</h3>
            <p>Making your brand identity stronger, one post at a time.</p>
          </div>
        </div>
      </div>
    </div>



      <CallToAction/>
      <ContactForm/>

    </div>



  )
}
