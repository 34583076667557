import React, { useState, useEffect } from 'react';
import './Navbar.css';
import logo from '../../media/adv.png';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSolid, setIsSolid] = useState(false);

  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsSolid(true);
    } else {
      setIsSolid(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
    closeMenu();
  };

  const handleClickContact = () => {
    handleNavigation('/contact-us-at-advance-techno');
  };

  return (
    <>
      <nav className={`navbar ${isSolid ? 'solid' : ''}`}>
        <div className="brand-name" onClick={() => handleNavigation('/')}>
          <img src={logo} alt="Advance Techno" className="logo" />
          <div className="brand-text">
            <span className="advance">Advance</span>
            <span className="techno">Techno</span>
          </div>
        </div>
        <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
          <li onClick={() => handleNavigation('/')}>Home</li>
          <li onClick={() => handleNavigation('/about-advance-techno')}>About</li>
          <li onClick={() => handleNavigation('/services-advance-techno-all')}>Services</li>
          <li onClick={() => handleNavigation('/our-works-advance-techno-works')}>Work</li>
          {/* <li onClick={() => handleNavigation('/advance_techno_cources')}>Courses</li> */}
          <li onClick={() => handleNavigation('/contact-us-at-advance-techno')}>Contact Us</li>
        </ul>
        <button className="cta-button" onClick={handleClickContact}>Hire us!</button>
        <div className="hamburger-menu" onClick={toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </nav>
      <div className={`side-menu ${isOpen ? 'open' : ''}`}>
        <div className="close-button" onClick={closeMenu}>
          &times;
        </div>
        <ul>
        <li onClick={() => handleNavigation('/')}>Home</li>
          <li onClick={() => handleNavigation('/about-advance-techno')}>About</li>
          <li onClick={() => handleNavigation('/services-advance-techno-all')}>Services</li>
          <li onClick={() => handleNavigation('/our-works-advance-techno-works')}>Work</li>
          {/* <li onClick={() => handleNavigation('/advance_techno_cources')}>Courses</li> */}
          <li onClick={() => handleNavigation('/contact-us-at-advance-techno')}>Contact Us</li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
