import React from 'react';
import bannerImage from '../../media/bannereb.webp'; // Update to relevant image
import brandingImage from '../../media/ebbb.webp'; // Update to relevant image
import '../EcommerceBranding/EcommerceBranding.css';
import facebookLogo from '../../media/fb.png';
import instagramLogo from '../../media/ig.png';
import googleLogo from '../../media/google.png';
import snapchatLogo from '../../media/sc.png';
import tiktokLogo from '../../media/tt.png';
import linkedinLogo from '../../media/ln.png';
import CallToAction from '../CallToAction/CallToAction';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faSearch, faChartLine, faUserTie, faHandshake, faRocket, faGavel, faClipboardCheck, faStore, faBoxOpen } from '@fortawesome/free-solid-svg-icons';

import p1 from '../../media/p3.webp'
import p2 from '../../media/p1.webp'
import p3 from '../../media/p5.webp'
import p4 from '../../media/p6.webp'
import p5 from '../../media/p2.webp'
import ContactForm from '../ContactForm/ContactForm';



export default function EcommerceBranding() {

  const navigate = useNavigate();

  const services = [
    {
      icon: faUserTie,
      title: 'Brand Strategy & Identity',
      description: 'Develop a strong brand strategy and identity to differentiate your eCommerce store in the competitive market. Our experts help you define your brand’s mission, vision, and values.'
    },
    {
      icon: faHandshake,
      title: 'Partnerships & Collaborations',
      description: 'Forge valuable partnerships and collaborations to expand your brand’s reach. We connect you with influencers and complementary businesses to amplify your brand message.'
    },
    {
      icon: faRocket,
      title: 'Product Launch & Campaigns',
      description: 'Ensure successful product launches with our comprehensive campaign strategies. From pre-launch buzz to post-launch analysis, we manage every aspect of your product introduction.'
    },
    {
      icon: faChartLine,
      title: 'Market Analysis & Insights',
      description: 'Gain a competitive edge with in-depth market analysis and insights. We provide detailed reports on market trends, customer behavior, and competitor strategies.'
    },
    {
      icon: faClipboardCheck,
      title: 'Content Creation & Marketing',
      description: 'Engage your audience with compelling content. Our team creates high-quality content for blogs, social media, email marketing, and more to build your brand’s online presence.'
    },
    {
      icon: faStore,
      title: 'Visual Branding & Design',
      description: 'Create a visually appealing brand with our design services. We develop logos, website designs, packaging, and other visual elements that resonate with your target audience.'
    },
    {
      icon: faBoxOpen,
      title: 'Packaging & Unboxing Experience',
      description: 'Enhance customer satisfaction with thoughtful packaging and unboxing experiences. We design packaging that reflects your brand and leaves a lasting impression on your customers.'
    },
    {
      icon: faGavel,
      title: 'Brand Protection & Trademarking',
      description: 'Safeguard your brand with our protection and trademarking services. We help you navigate legal requirements and protect your brand’s intellectual property.'
    },
    {
      icon: faShoppingCart,
      title: 'eCommerce Platform Optimization',
      description: 'Optimize your eCommerce platform for better performance and customer experience. We enhance site navigation, checkout processes, and overall functionality.'
    }
  ];

  const handleClickContact = () => {
    navigate(`/contact-us-at-advance-techno`);
  };

  const domains = [
    {
      title: 'Fashion & Apparel',
      description: 'Create a standout brand in the fashion industry with tailored branding strategies that attract and retain customers.',
      dark: true,
    },
    {
      title: 'Electronics',
      description: 'Establish your electronics store as a trusted brand through innovative branding and marketing tactics.',
      dark: false,
    },
    {
      title: 'Home & Garden',
      description: 'Build a strong brand presence in the home and garden sector with our comprehensive branding services.',
      dark: true,
    },
    {
      title: 'Health & Beauty',
      description: 'Enhance your health and beauty brand’s visibility and appeal with targeted branding strategies.',
      dark: false,
    },
    {
      title: 'Sports & Outdoors',
      description: 'Boost your sports and outdoors brand’s recognition and loyalty with effective branding and marketing.',
      dark: false,
    },
    {
      title: 'Toys & Games',
      description: 'Create a memorable brand in the toys and games industry with engaging and fun branding strategies.',
      dark: true,
    },
    {
      title: 'Automotive',
      description: 'Drive your automotive brand’s success with strategic branding that appeals to your target audience.',
      dark: false,
    },
    {
      title: 'Food & Beverage',
      description: 'Enhance your food and beverage brand’s identity and market presence with our expert branding services.',
      dark: true,
    }
  ];

  const testimonials = [
    {
      name: 'Aditya',
      rating: 5,
      text: 'Advance Techno’s branding services have transformed our online store. Our brand recognition and customer loyalty have significantly improved.',
      image: p1 // replace with actual image paths
    },
    {
      name: 'Sakshi',
      rating: 5,
      text: 'The team at Advance Techno has been exceptional. Their attention to detail and expertise in eCommerce branding have helped us achieve our business goals.',
      image: p2
    },
    {
      name: 'Rohit',
      rating: 5,
      text: 'We’ve seen incredible growth in our brand awareness thanks to Advance Techno’s branding services. Their strategic approach and dedication are unmatched.',
      image: p3
    },
    {
      name: 'Priya',
      rating: 5,
      text: 'Advance Techno exceeded our expectations. Their branding strategies have significantly improved our brand’s visibility and market position.',
      image: p5
    },
    {
      name: 'Vikram',
      rating: 5,
      text: 'Outstanding service and results! Advance Techno’s branding strategies have driven more recognition and loyalty to our online store.',
      image: p4
    },
    {
      name: 'Ananya',
      rating: 5,
      text: 'We highly recommend Advance Techno for eCommerce branding. Their expertise and commitment have helped us achieve impressive results.',
      image: p1
    }
  ];

  return (
    <div>
      <div className="homepage-banner">
        <img src={bannerImage} alt="Branding Banner" className="homepage-banner-image" />
        <div className="homepage-banner-content">
          <h1>Boost Your eCommerce Store’s Brand with Advance Techno's Expert Branding Services</h1>
          <p>Empowering Brands, Amplifying Reach, and Driving Success with Cutting-Edge Branding Strategies</p>
          <p>-<strong>Welcome to Advance Techno</strong></p>
          <button className="homepage-cta-button" onClick={handleClickContact}>Set Up A Call</button>
        </div>
      </div>

      <section>
        <div className="website-design-dev-container">
          <h1 className="website-design-dev-title">
            Branding <span className="highlight">SERVICES</span>
          </h1>
        </div>
      </section>

      <div className='homepage'>
        <section>
          <div className="branding-banner">
            <div className="branding-banner-content">
              <h1 className="branding-banner-heading">Expert eCommerce Branding Services</h1>
              <p>Welcome to Advance Techno's eCommerce branding services page. We specialize in crafting high-impact branding campaigns tailored to your online store’s goals. Let us help you achieve measurable results and drive growth for your business.</p>
              <button onClick={handleClickContact}>Contact Us</button>
            </div>
            <div className="branding-banner-image">
              <img src={brandingImage} alt="Branding Banner" />
            </div>
          </div>
        </section>
      </div>

      <div className="branding-our-services-container">
        <h2 className="branding-our-services-title">Our eCommerce Branding Services</h2>
        <p className="branding-our-services-subtitle">Enhance Your Brand’s Identity and Market Presence</p>
        <div className="branding-our-services-content">
          {services.map((service, index) => (
            <div className="branding-our-services-item" key={index}>
              <FontAwesomeIcon icon={service.icon} className="branding-our-services-icon" />
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="branding-domains-container">
        <h2 className="branding-domains-title">Our Specialized eCommerce Branding Domains</h2>
        <p className="branding-domains-subtitle">Tailored Branding Services for Your Industry</p>
        <div className="branding-domains-content">
          {domains.map((domain, index) => (
            <div key={index} className={`branding-domain ${domain.dark ? 'dark' : ''}`}>
              <h3>{domain.title}</h3>
              <p>{domain.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="branding-cta-container">
        <h2 className="branding-cta-title">Ready to Elevate Your eCommerce Brand?</h2>
        <p className="branding-cta-subtitle">Get in Touch with Our eCommerce Branding Experts Today</p>
        <button className="branding-cta-button" onClick={handleClickContact}>Contact Us</button>
      </div>

      <section>
        <div className="branding-testimonials-container">
          <h1 className="branding-testimonials-title">
            CLIENT <span className="highlight">TESTIMONIALS</span>
          </h1>
          <p className="branding-testimonials-subtitle">What Our Clients Are Saying About Us</p>
          <div className="branding-testimonials-content">
            {testimonials.map((testimonial, index) => (
              <div className="branding-testimonial" key={index}>
                <div className="branding-testimonial-image">
                  <img src={testimonial.image} alt={testimonial.name} />
                </div>
                <div className="branding-testimonial-content">
                  <h3>{testimonial.name}</h3>
                  <div className="branding-testimonial-rating">
                    {[...Array(testimonial.rating)].map((_, i) => (
                      <span key={i}>⭐</span>
                    ))}
                  </div>
                  <p>{testimonial.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section>
        <CallToAction />
        <ContactForm/>
      </section>
    </div>
  );
}
